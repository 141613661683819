import { SetOperator } from "../operator/operator3D.helper";
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
type Point2 = Communicator.Point2;
type Point3 = Communicator.Point3;
export default class WalkKeyboardOperator extends Communicator.Operator
    .CameraKeyboardWalkOperator {
    setOperator!: SetOperator;
    test!: Communicator.Operator.CameraPanOperator;
    viewer: Communicator.WebViewer;
    isHandleFocus = false;
    top = 0;
    left = 0;
    public onWell = false;
    constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.viewer = viewer;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onKeyUp(event: Communicator.Event.KeyInputEvent) {
        super.onKeyUp(event);
    }

    onKeyDown(event: Communicator.Event.KeyInputEvent) {
        super.onKeyDown(event);
    }
    async onMouseDown(event: Communicator.Event.MouseInputEvent) {
        const opeHandle = this.viewer.operatorManager.getOperator(
            Communicator.OperatorId.Handle
        ) as Communicator.Operator.HandleOperator;
        if (opeHandle && opeHandle._highlightedHandleId) {
            this.isHandleFocus = true;
        }
        else {
            this.top = event.getPosition().y
            this.left = event.getPosition().x
            this.isHandleFocus = false;
        }
        super.onMouseDown(event);
    }
    async onMouseUp(event: Communicator.Event.MouseInputEvent) {
        this.top = 0
        this.left = 0
        await this.resetCameraTarget();
        
    }
    onMouseMove(event: Communicator.Event.MouseInputEvent) {
        this.viewer.delayCapping()
        const topCurrent = event.getPosition().y
        const leftCurrent = event.getPosition().x
        let differenceTop = 0;
        let differenceLeft = 0;
        differenceLeft = Math.abs(leftCurrent - this.left)
        differenceTop = Math.abs(topCurrent - this.top)
        this.onWell = false
        if (!this.isHandleFocus){
            if(event.getButtons() === Communicator.Buttons.Middle) {
                this.onWell = true;
            }
            else if(event.getButtons() === Communicator.Buttons.Left) {
                setTimeout(() => {
                    if(differenceTop - differenceLeft > 3 ) { 
                        if(this.top !==0 && this.top < topCurrent ) {
                            this.walkBackward(this.getWalkSpeed())
                            this.top = topCurrent;
                        }
                        else if(this.top !==0 && this.top > topCurrent) {
                            this.walkForward(this.getWalkSpeed())                
                            this.top = topCurrent;
                        }
                    }
                    else if(differenceLeft - differenceTop > 3) {
                        this.viewer.delayCapping()
                        if(this.left !==0 && (leftCurrent - this.left>3 )) {
                            this.rotateLeft(this.getRotationSpeed()/50)
                            this.left = leftCurrent;
                        }
                        else if (this.left !== 0 && (this.left - leftCurrent > 3)) {
                            this.rotateRight(this.getRotationSpeed()/50)
                            this.left = leftCurrent
                        }
                    }
                }, 100);
            }
            else if(event.getButtons() === Communicator.Buttons.Right) {
                super.onMouseMove(event)
            }

        }
    }

    async onMousewheel(event: Communicator.Event.MouseWheelInputEvent) {
        if(this.onWell)
            return
        else {
            setTimeout(async () => {
                await this.resetCameraTarget();
            }, 100);
            const reverseScrollEvent = new Communicator.Event.MouseWheelInputEvent(
                event.getPosition().x,
                event.getPosition().y,
                event.getWheelDelta() * -1,
                event.getButtons(),
                event.getModifiers(),
                event.getEventType()
            );
            super.onMousewheel(reverseScrollEvent);
        }
        
    }

    private createBeginConfig(
        min: Point2,
        max: Point2,
        mustBeFullyContained: boolean
    ): Communicator.Util.IncrementalSelection.ScreenByAreaConfig {
        const config = new Communicator.IncrementalPickConfig();
        config.forceEffectiveSceneVisibilityMask = Communicator.SelectionMask.None;
        config.ignoreCuttingSections = false;
        config.ignoreUnrequestedInstances = true;

        if (mustBeFullyContained) {
            config.mustBeFullyContained = true;
        }

        return { pickConfig: config, areaCssMin: min, areaCssMax: max };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getNodeIds(list: any[]): number[] {
        return list.map((item) => item.getNodeId());
    }

    fixCameraTarget(func: string, point: Point3) {
        const camera = this.viewer.view.getCamera();
        const pos = camera.getPosition();
        const len = pos.copy().subtract(point).length();
        const dir = pos.copy().subtract(camera.getTarget()).normalize();
        const newTarget = pos.add(dir.scale(-len));
        camera.setTarget(newTarget);
        this.viewer.view.updateCamera(camera);
    }

    private async resetCameraTarget2(): Promise<void> {
        this.viewer.model.getNodesBounding([this.viewer.model.getAbsoluteRootNode()]).then((box) => {
            const cam = this.viewer.view.getCamera();
            const vecPosTar = Communicator.Point3.subtract(cam.getTarget().copy(), cam.getPosition().copy());
            const plan = Communicator.Plane.createFromPointAndNormal(box.center(), vecPosTar.normalize());
            const ray = new Communicator.Ray(cam.getTarget().copy(), vecPosTar);
            if(ray){
                const outPoin3 : Communicator.Point3 = Communicator.Point3.zero();
                plan.intersectsRay(ray, outPoin3)
                // eslint-disable-next-line no-debugger
                debugger
                this.fixCameraTarget("fix target Ray:", outPoin3);
            }
        });
    }

    private createSphereMesh(position : Communicator.Point3, color : Communicator.Color) {
        const sphereMeshData = Communicator.Util.generateSphereMeshData();
        const translationMatrix = new Communicator.Matrix();
        const translationScale = 0.01;
        translationMatrix.setTranslationComponent(position.x, position.y, position.z);
        translationMatrix.setScaleComponent(translationScale, translationScale, translationScale);
        this.viewer.model.createMesh(sphereMeshData).then((meshId) => {
            
            const meshInstanceData = new Communicator.MeshInstanceData(meshId);
            meshInstanceData.setMatrix(translationMatrix);
            meshInstanceData.setFaceColor(color);
            meshInstanceData.setCreationFlags(Communicator.MeshInstanceCreationFlags.SuppressCameraScale);
            this.viewer.model.createMeshInstance(meshInstanceData);
        });
    }

    private async resetCameraTarget(): Promise<void> {
        this.viewer.model.getNodesBounding([this.viewer.model.getAbsoluteRootNode()]).then((box) => {
            this.fixCameraTarget("fix target Ray:", box.center());
        });
    }

    async onDeactivate() {
        await this.resetCameraTarget();
        this.setWalkActive(false);
    }
}
