import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BimApi } from "api/bim.api";
import { PayloadView, Views } from "common/define";
import { GlobalState } from "common/global";
import { RootEpic, StringViewId } from "common/type-state";
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import Utils from "utils/utils";
import ViewsHelper from "./helper/views.helper";
import { setUpdateHoopsVisibleState } from "./tree.slice";
import { updateCuttingState } from "./viewer3D.slice";

interface ViewsState {
    currentViews: Views | null;
    currentViewSelected: string | undefined;
    loading: boolean;
    loadingSaveView: boolean;
}

const initState: ViewsState = {
    currentViews: null,
    currentViewSelected: undefined,
    loading: false,
    loadingSaveView: false
}

const viewsSlice = createSlice({
    name: 'views',
    initialState: initState,
    reducers: {
        getViews(state, action: PayloadAction<ViewId>) {
            state.loading = true
        },
        getViewsComplete(state, action: PayloadAction<Views | null>) {
            state.loading = false;
            // state.currentViews = action.payload;
            if (action.payload === null) {
                state.currentViews = null;
                return;
            }
            const { cadViews, savedViews } = action.payload;
            const viewsPayload: Views = {
                cadViews,
                savedViews: savedViews.map(v => ({ ...v, thumnail: undefined }))
            }
            state.currentViews = viewsPayload
        },
        setSelectedView(state, action: PayloadAction<StringViewId>) {
            const { viewId, payload } = action.payload;
            ViewsHelper.setViewSelected(viewId, payload);
            state.currentViewSelected = payload
        },
        resetViewSelected(state) {
            state.currentViewSelected = undefined
        },
        updateCurrentViewSelectedByViewActive(state, action: PayloadAction<ViewId>) {
            const currentViewSelected = ViewsHelper.getViewSelected(action.payload);
            state.currentViewSelected = currentViewSelected
        },
        createSaveView(state, action: PayloadAction<StringViewId>) {
            state.loadingSaveView = true
        },
        updateSaveViewComplete(state, action: PayloadAction<Views>) {
            state.loadingSaveView = false;
            // state.currentViews = action.payload;
            const { cadViews, savedViews } = action.payload;
            const viewsPayload: Views = {
                cadViews,
                savedViews: savedViews.map(v => ({ ...v, thumnail: undefined }))
            }
            state.currentViews = viewsPayload;
        },
        updateLoadingSaveView(state, action: PayloadAction<boolean>) {
            state.loadingSaveView = action.payload
        },
        selectedSaveView(state, action: PayloadAction<StringViewId>) {
            state.loadingSaveView = true;
        },
        renameView(state, action: PayloadAction<StringViewId & { newName: string }>) {
            state.loadingSaveView = true
        },
        deleteView(state, action: PayloadAction<StringViewId>) {
            state.loadingSaveView = true
        },
        clearState(state) {
            state.loading = false;
            state.loadingSaveView = false;
            state.currentViews = null;
            state.currentViewSelected = undefined
        }
    }
})
const viewsActions = viewsSlice.actions;
/** epics */
const getViews$: RootEpic = (action$, state$) => action$.pipe(
    filter(getViews.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const viewId = action.payload;
        const fileInfo = state.filesList.filesOrigin.find(f => f.viewId === viewId);
        if (fileInfo) {
            const views = ViewsHelper.getViews(viewId);
            if (views !== undefined) {
                const viewsByModelFileId = ViewsHelper.filterDataByModelFileId(fileInfo.modelFileId, views);
                return [
                    viewsActions.getViewsComplete(viewsByModelFileId),
                    viewsActions.updateCurrentViewSelectedByViewActive(viewId)
                ]
            } else {
                const { baseFileId, baseMajorRev, baseMinorRev } = fileInfo;
                const dataPost: PayloadView = {
                    baseFileId, baseMinorRev, baseMajorRev
                };
                return BimApi.getSaveAndCadViews(dataPost).pipe(
                    mergeMap(reViews => {
                        if (reViews) {
                            if (!reViews.cadViews)
                                reViews.cadViews = []
                            ViewsHelper.setViews(viewId, reViews);
                            const viewsByModelFileId = ViewsHelper.filterDataByModelFileId(fileInfo.modelFileId, reViews);
                            return [
                                viewsActions.getViewsComplete(viewsByModelFileId),
                                viewsActions.updateCurrentViewSelectedByViewActive(viewId)
                            ]
                        }
                        return [viewsActions.clearState()]
                    }),
                    catchError(err => [viewsActions.clearState()])
                )
            }
        }
        return [viewsActions.clearState()]
    })
)
const createSaveView$: RootEpic = (action$, state$) => action$.pipe(
    filter(createSaveView.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { viewId, payload: newName } = action.payload;
        const user = state.login.user?.userName || '';
        const fileInfo = state.filesList.filesOrigin.find(f => f.viewId === viewId);
        const viewer = GlobalState.getViewer3D(viewId);
        const viewIdFinal = GlobalState.getViewId(viewId);
        const sheetIdActive = state.sheets.active[viewIdFinal];
        const cuttingState = state.viewer3D.cuttingPlane[viewId];
        const planeBoxChildState = state.viewer3D.planeBoxChildState[viewId];
        // save bouding box
        const cuttingPlaneHelper = GlobalState.getCuttingPlaneHelper(viewId);
        let boundingBox: Communicator.Box | null = null;
        let eightCorners: Communicator.Point3[] = [];
        if (cuttingPlaneHelper && cuttingState.active?.includes('plane-box')) {
            boundingBox = cuttingPlaneHelper.cuttingPlaneOperator._boundingBox;
            eightCorners = cuttingPlaneHelper.cuttingPlaneOperator.getEightCorners();
        }

        const currentStateCutting = {
            ...cuttingState,
            planeBoxChildState: {
                ...planeBoxChildState,
                boundingBox,
                eightCorners
            }
        }

        if (fileInfo && viewer && cuttingState) {
            return ViewsHelper.createCustomViewSaved$(viewer, currentStateCutting, newName, 0, fileInfo.modelFileId, sheetIdActive, user).pipe(
                mergeMap(view => BimApi.updateView(view).pipe(map(_ => view))),
                mergeMap(view => {
                    const newViews = ViewsHelper.createSaveView(viewId, view);
                    const viewsByModelFileId = ViewsHelper.filterDataByModelFileId(fileInfo.modelFileId, newViews);
                    const viewSelected = view.uniqueId || '';
                    return [
                        viewsActions.updateSaveViewComplete(viewsByModelFileId),
                        setSelectedView({ viewId, payload: viewSelected })
                    ]
                }),
                catchError(err => [viewsActions.updateLoadingSaveView(false)])
            )
        } else {
            return [viewsActions.updateLoadingSaveView(false)]
        }
    })
)
const selectedSaveView$: RootEpic = (action$, state$) => action$.pipe(
    filter(selectedSaveView.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { viewId, payload: viewSelected } = action.payload;
        const cuttingCurrentState = state.viewer3D.cuttingPlane[viewId];
        return ViewsHelper.activeSaveView$(viewSelected, viewId, cuttingCurrentState).pipe(
            mergeMap(newCuttingState => {
                const saveView = ViewsHelper.getSaveViewById(viewSelected, viewId);
                const visState = saveView?.visibilityState ?? new Communicator.VisibilityState(true, new Set());
                if (newCuttingState) {
                    return [
                        updateCuttingState({ viewId, cuttingState: newCuttingState }),
                        viewsActions.updateLoadingSaveView(false),
                        setUpdateHoopsVisibleState(visState),
                    ]
                }
                return [
                    viewsActions.updateLoadingSaveView(false),
                    setUpdateHoopsVisibleState(visState),
                ]
            })
        )
    })
)
const renameView$: RootEpic = action$ => action$.pipe(
    filter(renameView.match),
    concatMap(action => {
        const { viewId, payload: idSaveView, newName } = action.payload;
        const view = ViewsHelper.getSaveViewById(idSaveView, viewId);
        if (view) {
            const newView = Utils.setPartialState(view, { Name: newName, modifiedDate: Date.now() });
            return BimApi.updateView(newView).pipe(
                mergeMap(_ => {
                    const newViews = ViewsHelper.updateSaveView(viewId, newView);
                    if (newViews) {
                        return [
                            viewsActions.updateSaveViewComplete(newViews)
                        ]
                    }
                    return [viewsActions.updateLoadingSaveView(false)]
                }),
                catchError(err => [viewsActions.updateLoadingSaveView(false)])
            )
        }
        return []
    })
)
const deleteView$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteView.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { viewId, payload: idSaveView } = action.payload;
        const modelFileId = state.multiViewer.viewActive.modelFileId;
        return BimApi.deleteView(idSaveView, modelFileId).pipe(
            map(_ => {
                const newViews = ViewsHelper.deleteView(viewId, idSaveView);
                if (newViews) {
                    const viewsByModelFileId = ViewsHelper.filterDataByModelFileId(modelFileId, newViews);
                    return viewsActions.updateSaveViewComplete(viewsByModelFileId)
                }
                return viewsActions.updateLoadingSaveView(false)
            }),
            catchError(err => [viewsActions.updateLoadingSaveView(false)])
        )
    })
)

export const ViewsEpics = [
    getViews$,
    createSaveView$,
    selectedSaveView$,
    renameView$,
    deleteView$
]
export const {
    getViews,
    setSelectedView,
    createSaveView,
    selectedSaveView,
    renameView,
    deleteView,
    resetViewSelected
} = viewsActions;
export const viewsReducer = viewsSlice.reducer;
