import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColorSetting, EffectSetting, GeneralSettingState, SettingState } from "common/define";
import { GlobalState } from "common/global";
import { RootEpic } from "common/type-state";
import SettingHelper, { defaultColorSetting, defaultEffectSetting, defaultPointCloudSetting } from 'redux/helper/setting.helper';
import { filter, concatMap, switchMap, withLatestFrom } from "rxjs/operators";
import Utils from "utils/utils";
import { defaultSetting } from './helper/setting.helper';

const initState = defaultSetting;
const settingSlice = createSlice({
    name: 'setting',
    initialState: initState,
    reducers: {
        setGeneral: (state, action: PayloadAction<GeneralSettingState>) => {
            state.general = action.payload;
        },
        setEffect: (state, action: PayloadAction<EffectSetting>) => {
            state.effect = action.payload;
        },
        setColor: (state, action: PayloadAction<ColorSetting>) => {
            state.color = action.payload;
        },
        setFetchingStatus: (state, action: PayloadAction<boolean>) => {
            state.fetchSettingFail = action.payload;
        },
        setCustomSetting: (state, action: PayloadAction<boolean>) => {
            state.customSetting = action.payload;
        },
        setSettings: (state, action: PayloadAction<SettingState>) => {
            state.general = action.payload.general;
            state.effect = action.payload.effect;
            state.color = action.payload.color;
            state.fetchSettingFail = action.payload.fetchSettingFail;
            state.customSetting = action.payload.customSetting;
        },
        saveAllSetting: (state, action: PayloadAction<SettingState>) => {
            const setting = action.payload;
            state.general = setting.general;
            state.effect = setting.effect;
            state.color = setting.color;
            Utils.setLocalStorage('setting', setting)
            return
        },
        fetchAllSetting: (state, action: PayloadAction<undefined>) => { return },
        applySetting: (state, action: PayloadAction<SettingState>) => { return },
        cancelSettingChanges: (stae, action: PayloadAction<undefined>) => { return },
        restoreDefaultSetting: (state, action: PayloadAction<undefined>) => { return },
        cancelGeneralSetting: (state, action: PayloadAction<undefined>) => { return },
        cancelEffectSetting: (state, action: PayloadAction<undefined>) => { return },
        cancelColorSetting: (state, action: PayloadAction<undefined>) => { return },
    }
})
const fetchSetting$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchAllSetting.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const setting = state.setting;
        const oldSetting: SettingState | null = Utils.getValueLocalStorage('setting');
        const defaultGeneral = SettingHelper.getDefaultSheetColor(state.multiViewer.viewActive.extension);
        const defaultSetting: SettingState = {
            ...setting,
            general: defaultGeneral
        }
        const finalSetting = oldSetting?? defaultSetting;
        Utils.setLocalStorage('setting', finalSetting);
        return [setSettings(finalSetting)]
    })
);
const applySetting$: RootEpic = (action$, state$) => action$.pipe(
    filter(applySetting.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const displayViews = state.multiViewer.listViewerDisplay;
        for (const displayview of displayViews) {
            const webViewer = GlobalState.getViewer3D(displayview.viewId);
            if (webViewer) {
                SettingHelper.setGeneralSetting(webViewer, action.payload.general, displayview.viewId);
                SettingHelper.setEffectSetting(webViewer, action.payload.effect);
                SettingHelper.setColorSetting(webViewer, action.payload.color);
                SettingHelper.setPointCloudSetting(webViewer, defaultPointCloudSetting);
            };
        };
        return [];
    })
);
const cancelSettingChanges$: RootEpic = (action$, state$) => action$.pipe(
    filter(cancelSettingChanges.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const setting: SettingState | null = Utils.getValueLocalStorage('setting');
        const defaultGeneral = SettingHelper.getDefaultSheetColor(state.multiViewer.viewActive.extension);
        const defaultSetting: SettingState = {
            general: defaultGeneral,
            effect: defaultEffectSetting,
            color: defaultColorSetting,
            customSetting: false
        }
        const finalSetting = setting?? defaultSetting;
        const displayIDs = state.multiViewer.listViewerDisplay.map(view => view.viewId)
        for (const displayId of displayIDs) {
            const webViewer = GlobalState.getViewer3D(displayId);
            if (webViewer) {
                SettingHelper.setGeneralSetting(webViewer, finalSetting.general, displayId);
                SettingHelper.setEffectSetting(webViewer, finalSetting.effect);
                SettingHelper.setColorSetting(webViewer, finalSetting.color);
            };
        };
        return [];
    })
);
const cancelGeneralSetting$: RootEpic = (action$, state$) => action$.pipe(
    filter(cancelGeneralSetting.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const defaultGeneral = SettingHelper.getDefaultSheetColor(state.multiViewer.viewActive.extension);
        const setting: SettingState | null = Utils.getValueLocalStorage('setting');
        const finalSetting = setting? setting.general : defaultGeneral;
        const displayIDs = state.multiViewer.listViewerDisplay.map(view => view.viewId)
        for (const displayId of displayIDs) {
            const webViewer = GlobalState.getViewer3D(displayId);
            if (webViewer) {
                SettingHelper.setGeneralSetting(webViewer, finalSetting, displayId);
            };
        };
        return [];
    })
);
const cancelEffectSetting$: RootEpic = (action$, state$) => action$.pipe(
    filter(cancelEffectSetting.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const setting: SettingState | null = Utils.getValueLocalStorage('setting');
        const finalSetting = setting? setting.effect : defaultEffectSetting;
        const displayIDs = state.multiViewer.listViewerDisplay.map(view => view.viewId)
        for (const displayId of displayIDs) {
            const webViewer = GlobalState.getViewer3D(displayId);
            if (webViewer) {
                SettingHelper.setEffectSetting(webViewer, finalSetting);
            };
        };
        return [];
    })
);
const cancelColorSetting$: RootEpic = (action$, state$) => action$.pipe(
    filter(cancelColorSetting.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const setting: SettingState | null = Utils.getValueLocalStorage('setting');
        const finalSetting = setting? setting.color : defaultColorSetting;
        const displayIDs = state.multiViewer.listViewerDisplay.map(view => view.viewId)
        for (const displayId of displayIDs) {
            const webViewer = GlobalState.getViewer3D(displayId);
            if (webViewer) {
                SettingHelper.setColorSetting(webViewer, finalSetting);
            };
        };
        return [];
    })
);
const restoreDefaultSetting$: RootEpic = (action$, state$) => action$.pipe(
    filter(restoreDefaultSetting.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const listDisplay = state.multiViewer.listViewerDisplay;
        const extension = state.multiViewer.viewActive.extension;
        const defaultGeneral = SettingHelper.getDefaultSheetColor(extension)
        for (const model of listDisplay) {
            const webViewer = GlobalState.getViewer3D(model.viewId);
            if (webViewer) {
                SettingHelper.setGeneralSetting(webViewer, defaultGeneral, model.viewId);
                SettingHelper.setEffectSetting(webViewer, defaultEffectSetting);
                SettingHelper.setColorSetting(webViewer, defaultColorSetting);
                SettingHelper.setPointCloudSetting(webViewer, defaultPointCloudSetting);
            }
        }
        return [];
    })
)
export const SettingActionEpics = [
    fetchSetting$,
    applySetting$,
    cancelSettingChanges$,
    restoreDefaultSetting$,
    cancelGeneralSetting$,
    cancelEffectSetting$,
    cancelColorSetting$
]
export const {
    setGeneral,
    setEffect,
    setColor,
    saveAllSetting,
    setCustomSetting,
    fetchAllSetting,
    applySetting,
    cancelSettingChanges,
    restoreDefaultSetting,
    setFetchingStatus,
    setSettings,
    cancelGeneralSetting,
    cancelEffectSetting,
    cancelColorSetting
} = settingSlice.actions;

export default settingSlice.reducer
