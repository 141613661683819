import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PanelFocus, TabTreeViewsKeys } from "common/define";
import { GlobalState } from "common/global";
import { ModeLeftPanel, ModeRightPanel, RootEpic, SortOrder } from "common/type-state";
import PdfHelper from "container/pdf-viewer/helper/pdf.helper";
import { filter, switchMap, withLatestFrom } from "rxjs/operators";
import Utils from "utils/utils";
import { setCurrentInfoNotepin } from "./notepin.slice";

interface ContextMenuVis {
    top: number;
    left: number;
}
interface PanelVisibleState {
    leftPanelVisible: boolean;
    rightPanelVisible: boolean;
    currentModeLeftPanel: ModeLeftPanel | null;
    currentTabTreeViews: TabTreeViewsKeys;
    visibleDialogSaveViewBookmark: boolean;
    visiblePropertyPanel: boolean,
    modeRightPanel: ModeRightPanel | undefined,
    linkedObjectPanelVisible: boolean,
    modelTreePanelVisible: boolean,
    dragging: boolean,
    floatFocus: PanelFocus[],
    contextMenuVis: ContextMenuVis | undefined,
    settingPanelVisible: boolean,
    cuttingPlaneVisible: {
        [viewId: string]: boolean;
    },
    clippingVisible: {
        [viewId: string]: boolean;
    },
    sheetSortOrder: SortOrder | undefined,
    sheetSearch: string,
    cuttingPlaneRef: DOMRect | undefined,
    clippingPlaneRef: DOMRect | undefined,
    propertySearch: string,
    propertyFilter: CompositeFilterDescriptor | undefined,
    propertyHeight: number
}

const initialState: PanelVisibleState = {
    leftPanelVisible: false,
    rightPanelVisible: false,
    currentModeLeftPanel: 'FilesList',
    currentTabTreeViews: TabTreeViewsKeys.Models,
    visibleDialogSaveViewBookmark: false,
    visiblePropertyPanel: false,
    modeRightPanel: undefined,
    linkedObjectPanelVisible: false,
    modelTreePanelVisible: false,
    dragging: false,
    floatFocus: [],
    contextMenuVis: undefined,
    settingPanelVisible: false,
    sheetSortOrder: undefined,
    sheetSearch: '',
    cuttingPlaneVisible: {},
    clippingVisible: {},
    cuttingPlaneRef: undefined,
    clippingPlaneRef: undefined,
    propertySearch: '',
    propertyFilter: undefined,
    propertyHeight: 350
};

const panelVisibleSlice = createSlice({
    name: 'panel',
    initialState: initialState,
    reducers: {
        setVisibleLeftPanel: (state, action: PayloadAction<boolean>) => {
            const value = action.payload;
            state.leftPanelVisible = value;
            state.currentTabTreeViews = TabTreeViewsKeys.Models
        },
        setVisibleRightPanel: (state, action: PayloadAction<boolean>) => {
            state.rightPanelVisible = action.payload;
        },
        toggleVisibleLeftPanel: (state) => {
            state.leftPanelVisible = !state.leftPanelVisible;
        },
        toggleVisibleRightPanel: (state) => {
            state.rightPanelVisible = !state.rightPanelVisible;
        },
        noVisibleAllPanel: (state) => {
            state.leftPanelVisible = false;
            state.rightPanelVisible = false;
        },
        setCurrentModeLeftPanel: (state, action: PayloadAction<ModeLeftPanel | null>) => {
            if (state.leftPanelVisible === false) {
                state.leftPanelVisible = true;
            }
            state.currentModeLeftPanel = action.payload;
        },
        toggleRightPanelWithConfirm(state) { return },
        setCurrentTabTreeViews(state, action: PayloadAction<TabTreeViewsKeys>) {
            state.currentTabTreeViews = action.payload
        },
        updateVisibleDialogSaveViewBookmark(state, action: PayloadAction<boolean>) {
            state.visibleDialogSaveViewBookmark = action.payload;
        },
        togglePropertyPanel(state) {
            state.visiblePropertyPanel = !state.visiblePropertyPanel
        },
        setPropertyPanelVisible(state, action: PayloadAction<boolean>) {
            state.visiblePropertyPanel = action.payload;
        },
        setModeMenu(state, action: PayloadAction<ModeRightPanel | undefined>) {
            const mode = action.payload;
            state.modeRightPanel = mode;
            if (mode && !state.rightPanelVisible) {
                state.rightPanelVisible = true
            } else if (mode === undefined) {
                state.rightPanelVisible = false;
            }
            setCurrentInfoNotepin(undefined);
        },
        setLinkedObjectPanelVisible: (state, action: PayloadAction<boolean>) => {
            state.linkedObjectPanelVisible = action.payload;
        },
        toggleLinkedObject: (state, action: PayloadAction<undefined>) => {
            state.linkedObjectPanelVisible = !state.linkedObjectPanelVisible;
        },
        toggleModelTreePanel(state) {
            state.modelTreePanelVisible = !state.modelTreePanelVisible;
        },
        setModelTreePanelVisible(state, action: PayloadAction<boolean>) {
            state.modelTreePanelVisible = action.payload;
        },
        toggleSettingPanel(state) {
            state.settingPanelVisible = !state.settingPanelVisible;
        },
        setDraggingState(state, action: PayloadAction<boolean>) {
            state.dragging = action.payload;
        },
        setFloatFocus(state, action: PayloadAction<PanelFocus>) {
            state.floatFocus = [...state.floatFocus.filter(v => v !== action.payload), ...[action.payload]];
        },
        changeModeRightPanel(state, action: PayloadAction<ModeRightPanel | undefined>) { return },
        setContextMenuVis(state, action: PayloadAction<ContextMenuVis | undefined>) {
            state.contextMenuVis = state.contextMenuVis ? undefined : action.payload;
        },
        setSettingPanelVisible(state, action: PayloadAction<boolean>) {
            state.settingPanelVisible = action.payload;
        },
        setSheetSortOrder(state, action: PayloadAction<SortOrder>) {
            state.sheetSortOrder = action.payload;
        },
        setVisibleCuttingPlane(state, action: PayloadAction<{ viewId: ViewId, visible?: boolean }>) {
            const { viewId, visible } = action.payload;
            if (visible !== undefined) {
                state.cuttingPlaneVisible[viewId] = visible;
                return;
            }
            state.cuttingPlaneVisible[viewId] = !state.cuttingPlaneVisible[viewId];
        },
        setVisibleClippingPlane(state, action: PayloadAction<{ viewId: ViewId, visible?: boolean }>) {
            const { viewId, visible } = action.payload;
            if (visible !== undefined) {
                state.clippingVisible[viewId] = visible;
                return;
            }
            state.clippingVisible[viewId] = !state.clippingVisible[viewId];
        },
        setSheetSearch(state, action: PayloadAction<string>) {
            state.sheetSearch = action.payload;
        },
        setCuttingPlaneRef(state, action: PayloadAction<DOMRect | undefined>) {
            state.cuttingPlaneRef = action.payload;
        },
        setClippinglaneRef(state, action: PayloadAction<DOMRect | undefined>) {
            state.clippingPlaneRef = action.payload;
        },
        setPropertySearch(state, action: PayloadAction<string>) {
            state.propertySearch = action.payload;
        },
        setPropertyFilter(state, action: PayloadAction<CompositeFilterDescriptor | undefined>) {
            state.propertyFilter = action.payload;
        },
        deleteFLoatingVisibleByViewId(state, action: PayloadAction<ViewId>) {
            const viewId = action.payload;
            const reDeleteCutting = Utils.unsetPathObject(state.cuttingPlaneVisible, viewId);
            reDeleteCutting && (state.cuttingPlaneVisible = reDeleteCutting);
            const reDeleteClipping = Utils.unsetPathObject(state.clippingVisible, viewId);
            reDeleteClipping && (state.clippingVisible = reDeleteClipping);
        },
        setPropertyHeight(state, action: PayloadAction<number>) {
            state.propertyHeight = action.payload;
        }
    }
});

const changeModeRightPanel$: RootEpic = (action$, state$) => action$.pipe(
    filter(changeModeRightPanel.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const currMode = state.panelVisible.modeRightPanel;
        const modeInput = action.payload;
        const { viewActive } = state.multiViewer;
        const pdfViewer = GlobalState.getPdfViewer(viewActive.viewId);
        const stateHandler = pdfViewer && pdfViewer.getStateHandlerManager();
        stateHandler && stateHandler.switchTo('select-text-image');
        const tempDiv = document.getElementsByClassName('pdf-textbox-temp')[0];
        if (pdfViewer && (modeInput !== currMode) && tempDiv) {
            const pageHandle = PdfHelper.pageHandler(pdfViewer)
            const pageUi = pageHandle[0] as HTMLElement;
            pageUi.parentElement && pageUi.parentElement.removeChild(tempDiv)
        }
        return [
            panelVisibleSlice.actions.setModeMenu(modeInput)
        ];
    })
)
export const PanelVisibleEpics = [
    changeModeRightPanel$
];

export const {
    setVisibleLeftPanel,
    setVisibleRightPanel,
    setCurrentModeLeftPanel,
    setCurrentTabTreeViews,
    updateVisibleDialogSaveViewBookmark,
    toggleVisibleLeftPanel,
    toggleVisibleRightPanel,
    togglePropertyPanel,
    changeModeRightPanel,
    setModeMenu,
    setLinkedObjectPanelVisible,
    toggleModelTreePanel,
    toggleSettingPanel,
    setModelTreePanelVisible,
    setDraggingState,
    toggleLinkedObject,
    setFloatFocus,
    setContextMenuVis,
    setSettingPanelVisible,
    setSheetSortOrder,
    setSheetSearch,
    setVisibleCuttingPlane,
    setVisibleClippingPlane,
    setCuttingPlaneRef,
    setClippinglaneRef,
    setPropertySearch,
    setPropertyFilter,
    setPropertyPanelVisible,
    deleteFLoatingVisibleByViewId,
    setPropertyHeight
} = panelVisibleSlice.actions;
export default panelVisibleSlice.reducer;