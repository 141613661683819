import { createSelector } from "@reduxjs/toolkit";
import { selectSelfPanel } from "./self.selector";

export const getCuttingPlaneVisible = createSelector(
    selectSelfPanel,
    (_: unknown, viewId: string) => viewId,
    (state, viewId) => state.cuttingPlaneVisible[viewId]
)
export const getClippingPanelVisible = createSelector(
    selectSelfPanel,
    (_: unknown, viewId: string) => viewId,
    (state, viewId) => state.clippingVisible[viewId],
)
export const getLinkedObjectPanelVisible = createSelector(
    selectSelfPanel,
    state => state.linkedObjectPanelVisible
)
export const getModelTreePanelVisible = createSelector(
    selectSelfPanel,
    state => state.modelTreePanelVisible
)
export const getSettingPanelVisible = createSelector(
    selectSelfPanel,
    state => state.settingPanelVisible
)
export const getCuttingPlaneRef = createSelector(
    selectSelfPanel,
    (state) => state.cuttingPlaneRef,
)
export const getClippingPlaneRef = createSelector(
    selectSelfPanel,
    (state) => state.clippingPlaneRef
)

export const getPanelState = createSelector(
    selectSelfPanel,
    (state) => state
)
export const getVisiblePropertyPanel = createSelector(
    selectSelfPanel,
    (state) => state.visiblePropertyPanel
)
export const getVisibleSaveViewBookmark = createSelector(
    selectSelfPanel,
    (state) => state.visibleDialogSaveViewBookmark
)
export const getVisibleLeftPanel = createSelector(
    selectSelfPanel,
    (state) => state.leftPanelVisible
)
export const getCurrentTabTreeViews = createSelector(
    selectSelfPanel,
    (state) => state.currentTabTreeViews
)
export const getCurrentModeLeftPanel = createSelector(
    selectSelfPanel,
    (state) => state.currentModeLeftPanel
)
export const getModeRightPanel = createSelector(
    selectSelfPanel,
    (state) => state.modeRightPanel
)
export const getSheetSortOrder = createSelector(
    selectSelfPanel,
    (state) => state.sheetSortOrder
)
export const getSheetSearch = createSelector(
    selectSelfPanel,
    (state) => state.sheetSearch
)
export const getRightPanelVisible = createSelector(
    selectSelfPanel,
    (state) => state.rightPanelVisible
)
export const getPropertySearch = createSelector(
    selectSelfPanel,
    (state) => state.propertySearch
)
export const getPropertyFilter = createSelector(
    selectSelfPanel,
    (state) => state.propertyFilter
)
export const getFloatFocus = createSelector(
    selectSelfPanel,
    (state) => state.floatFocus
)
export const getGridHeight = createSelector(
    selectSelfPanel,
    (state) => state.propertyHeight
)

export const getContextMenuVis = createSelector(
    selectSelfPanel,
    (state) => state.contextMenuVis
)