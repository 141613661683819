/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BimApi } from 'api/bim.api';
import { CombineChild, FileExtension, FileInfo, FilesTree } from 'common/define';
import { GlobalState } from 'common/global';
import { from, Observable, throwError } from 'rxjs';
import { concatMap, map, reduce, switchMap } from 'rxjs/operators';
import Utils, { Lodash } from 'utils/utils';
import { TreeHelper } from '.';
import { TreeConvertType } from './tree.helper';

type MapChildren = {
    mapKeyNode: [string, number[]],
    fileInfo: FileInfo
}

/** function */
function loadSubModelChild(webViewer: Communicator.WebViewer, fileInfo: FileInfo): Observable<[ViewId, number[]]> {
    return new Observable<[ViewId, number[]]>(subscriber => {
        const modelName = Utils.getModelNameByStreamLocation(fileInfo);
        const rootNode = webViewer.model.getAbsoluteRootNode();
        const nodeIdModel = webViewer.model.createNode(rootNode, fileInfo.filename);
        if (fileInfo.linksInformation?.Transform) {
            const mat = Communicator.Matrix.createFromArray(fileInfo.linksInformation?.Transform);
            // const mat = Communicator.Matrix.createFromArray([ 0.656059028990513, 0.754709580222767, 0.0, 0.0, -0.754709580222767, 0.656059028990513, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 2164.324021482401, -1726.9929023550656, 0.0, 1.0 ]);
            webViewer.model.setNodeMatrix(nodeIdModel, mat, true);
        }
        
        webViewer.model.loadSubtreeFromModel(nodeIdModel, modelName).then(val => {
            subscriber.next([fileInfo.viewId, val]);
            subscriber.complete();
        }).catch(err => {
            subscriber.error(err);
            subscriber.complete();
        })
    })
}
function loadSubModelChildTree(fileInfo: FileInfo) {
    const { filename, modelFileId } = fileInfo;
    const extension = Utils.getFileExtension(filename);
    // let api = BimApi.getTree(modelFileId);
    // if(extension === FileExtension.DWG || extension === FileExtension.DGN){
    //     api = BimApi.getTreeWithExtension(modelFileId, extension);
    // }else if (extension === FileExtension.Revit) {
    //     api = BimApi.getTreeRvtNew(modelFileId);
    // } else if (extension === FileExtension.IFC) {
    //     api = BimApi.getTreeIfc(modelFileId)
    // }
    const api: Observable<FilesTree | null> = BimApi.getTreeStringWithExtension(modelFileId, extension);
    const getDataMap = GlobalState.getTreeDataByModelFileId(modelFileId);
    if (!getDataMap) {
        return api.pipe(
            switchMap(res => {
                if (res) {
                    let type: TreeConvertType = 'default';
                    if (FileExtension.Revit === extension || FileExtension.IFC === extension) {
                        type = 'category'
                    }
                    return TreeHelper.mapTreeWorker(res, modelFileId, type);
                }
                GlobalState.mapTreeData.set(modelFileId, { data: [], extra: {} });
                GlobalState.GenerateMapTreeDataNode(modelFileId);
                return throwError('Err request sub tree');
            }),
            map(mapTree => {
                GlobalState.mapTreeData.set(modelFileId, mapTree);
                GlobalState.GenerateMapTreeDataNode(modelFileId);
                return modelFileId
            }),
        )
    }
    return from([modelFileId])
}
/** export function */
export type CurrentCombine = {
    children: CombineChild[],
    modelFileIdRoot: ModelFileId,
    rootId: ViewId
}
export interface CombineState {
    mapCombineModel: {
        [viewId: string]: CombineChild[],
    }
}

export function addModelCombineHelper(listAdd: ViewId[], viewIdFinal: ViewId, currentListCombineByViewId: CombineChild[]) {
    // const currentListCombineByViewId = getCurrentCombine(viewIdFinal);
    const currentListViewId = currentListCombineByViewId.map(com => com.viewId);
    const listDiff = Lodash.difference(listAdd, [...currentListViewId, viewIdFinal]);
    if (listDiff.length > 0) {
        const webViewerRoot = GlobalState.getViewer3D(viewIdFinal);
        if (webViewerRoot) {
            return {
                webViewerRoot,
                viewIdFinal,
                listViewIdAdd: listDiff
            }
        }
    }
}
export function addSubModel3DHelper$(webViewer: Communicator.WebViewer, listFileInfoAdd: FileInfo[], viewIdFinal: ViewId): Observable<CombineChild[]> {
    return from(listFileInfoAdd).pipe(
        concatMap(fileInfo => loadSubModelChild(webViewer, fileInfo).pipe(
            map(mapKeyNode => { return { mapKeyNode, fileInfo } })
        )),
        // concatMap((input: MapChildren) => loadSubModelChildTree(input.fileInfo).pipe(map(_ => input))),
        map(input => {
            const item: CombineChild = {
                fileInfo: input.fileInfo,
                viewId: input.mapKeyNode[0],
                nodeRootChild: input.mapKeyNode[1],
                modelFileId: input.fileInfo.modelFileId,
            }
            return item
        }),
        reduce<CombineChild, CombineChild[]>((acc, val) => {
            return [...acc, val]
        }, []),
        map(val => {
            // addChildrenCombine(viewIdFinal, val);
            return val;
        })
    )
}
// export function getCurrentCombine(viewId: ViewId): CombineChild[] {
//     return GlobalState.mapCombineModel.get(viewId) || []
// }
// export function addChildrenCombine(viewId: ViewId, val: CombineChild[]): CombineChild[] {
//     const pre = GlobalState.mapCombineModel.get(viewId) || [];
//     const newVal = Lodash.unionWith(pre, val, Lodash.isEqual);
//     GlobalState.mapCombineModel.set(viewId, newVal);
//     return newVal;
// }
export function deleteChildCombined$(viewId: ViewId, child: CombineChild, currentCombine: CombineChild[]): Observable<CombineChild[] | undefined> {
    const webViewer = GlobalState.getViewer3D(viewId);
    if (webViewer) {
        const parentNodeChild = webViewer.model.getNodeParent(child.nodeRootChild[0]);
        if (parentNodeChild) {
            return from(webViewer.model.deleteNode(parentNodeChild)).pipe(
                map(_ => {
                    const children = Lodash.cloneDeep(currentCombine);
                    const newChildren = Lodash.remove(children, (item) => item.viewId !== child.viewId);
                    // cloneCurrentCombine.children = newChildren;
                    // GlobalState.mapCombineModel.set(viewId, newChildren);

                    // delete mapSheetData nay de lan sau tu load lai, danh sach sheet merge đã bỏ qua sheet 3D
                    const baseViewId = GlobalState.getViewId(child.viewId);
                    GlobalState.mapSheetData.delete(baseViewId);

                    return newChildren
                })
            )
        }
    }
    return from([undefined])
}
