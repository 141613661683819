import { FormatViewer } from "common/define";
import { IconName } from "common/define-name-icon";
import { GlobalState } from "common/global";
import { NavOperatorState } from "common/type-state";
import { Operator3DType, OperatorPdfType, OperatorType } from "common/type-viewer";

type ModeFormat = '3d' | '2d' | 'pdf';
export type KeyChildren = 'zoom' | 'orbit' | 'drawMode';
export type KeyGroupOperator = 'default' | 'adv' | 'adv1' | 'adv2';
export interface ButtonOperatorBase {
    name: string;
    icon: IconName;
    keyChildren?: KeyChildren;
    type: Operator3DType | OperatorPdfType;
    keyGroup?: KeyGroupOperator;
}

const arrBtnOperatorMain: ButtonOperatorBase[] = [
    {
        name: 'Home',
        icon: 'home',
        keyGroup: 'default',
        type: 'home'
    },
    {
        name: 'Select',
        icon: 'select',
        keyGroup: 'default',
        type: 'select'
    },
    {
        name: 'Area Select',
        icon: 'new_edit_markup',
        keyGroup: 'default',
        type: 'areaSelect'
    },
    {
        name: 'Pan',
        icon: 'op_pan',
        type: 'pan',
        keyGroup: 'default'
    },
    {
        name: 'Zoom',
        icon: 'zoomin',
        type: 'zoomIn',
        keyChildren: 'zoom',
        keyGroup: 'default'
    },
    {
        name: 'Rotate',
        icon: 'rotateRight',
        type: 'rotateZ',
        keyGroup: 'default'
    },
    {
        name: 'Orbit',
        icon: 'op_orbit',
        type: 'orbit',
        keyChildren: 'orbit',
        keyGroup: 'default'
    },
    // {
    //     name: 'Model Tree',
    //     icon: 'tree',
    //     keyGroup: 'adv',
    //     type: 'model-tree'
    // },
    // {
    //     name: 'Linked Objects',
    //     icon: 'File',
    //     keyGroup: 'adv',
    //     type: 'linkedObjects'
    // },
    {
        name: 'Save',
        icon: 'Add',
        keyGroup: 'adv2',
        type: 'save'
    },
    {
        name: 'Settings',
        icon: 'setting',
        keyGroup: 'adv',
        type: 'setting'
    },
    {
        name: 'Line settings',
        icon: 'pencil_edit',
        keyGroup: 'adv',
        type: 'lineSetting'
    },
    {
        name: 'Format settings',
        icon: 'Font',
        keyGroup: 'adv',
        type: 'formatSetting'
    },
    {
        name: 'Shaded',
        icon: 'shaded',
        keyGroup: 'default',
        keyChildren: 'drawMode',
        type: 'drawMode-shaded'
    },
    {
        name: 'Synchronized',
        icon: 'syncCircularArrows',
        keyGroup: 'default',
        type: 'synchronized'
    },
    {
        name: 'Cutting Plane',
        icon: 'cutting',
        keyGroup: 'adv',
        type: 'cutting-plane'
    },
    {
        name: 'Clipping Planes',
        icon: 'clippingPlane',
        keyGroup: 'adv',
        type: 'clipping-commands'
    },
    {
        name: 'Explode',
        icon: 'op_explode',
        keyGroup: 'adv',
        type: 'explode'
    },
    {
        name: 'Property Panel',
        icon: 'table',
        keyGroup: 'adv',
        type: 'propertyPanel'
    },
    {
        name: 'Plan mode',
        icon: 'page',
        keyGroup: 'adv',
        type: 'plan-mode'
    },
    {
        name: 'Document mode',
        icon: 'multi-page',
        keyGroup: 'adv',
        type: 'document-mode'
    },
    {
        name: 'First page',
        icon: 'top-page',
        keyGroup: 'adv1',
        type: 'first-page'
    },
    {
        name: 'Previous page',
        icon: 'pre-page-op',
        keyGroup: 'adv1',
        type: 'previous-page'
    },
    {
        name: 'Current page',
        icon: 'pre-page-op',
        keyGroup: 'adv1',
        type: 'current-page'
    },
    {
        name: 'Next page',
        icon: 'next-page-op',
        keyGroup: 'adv1',
        type: 'next-page'
    },
    {
        name: 'Last page',
        icon: 'bottom-page',
        keyGroup: 'adv1',
        type: 'last-page'
    },
];
const arrBtnChildrenZoom: ButtonOperatorBase[] = [
    {
        name: 'Zoom In',
        icon: 'zoomin',
        type: 'zoomIn',
    },
    {
        name: 'Zoom Out',
        icon: 'zoomout',
        type: 'zoomOut',
    },
    {
        name: 'Zoom Window',
        icon: 'zoomwindow',
        type: 'zoomWindow',
    },
    {
        name: 'Zoom Fit',
        icon: 'zoomfit',
        type: 'zoomFit',
    },

];
const arrBtnChildrenOrbit: ButtonOperatorBase[] = [
    {
        name: 'Orbit',
        icon: 'op_orbit',
        type: 'orbit',
    },
    {
        name: 'Rotate Z',
        icon: 'rotateRight',
        type: 'rotateZ',
    },
    {
        name: 'Walk Through',
        icon: 'person',
        type: 'walkThrough',
    },
];
const arrBtnChildrenRender: ButtonOperatorBase[] = [
    {
        name: 'Ghosting',
        icon: 'ghostCube',
        type: 'drawMode-ghosting',
    },
    {
        name: 'X-ray',
        icon: 'xray',
        type: 'drawMode-xray',
    },
    {
        name: 'Wireframe',
        icon: 'wireframe',
        type: 'drawMode-wireframe',
    },
    {
        name: 'Shaded Wire',
        icon: 'wireframe_on_shaded',
        type: 'drawMode-shaded-wire',
    },
    {
        name: 'Shaded',
        icon: 'shaded',
        type: 'drawMode-shaded'
    },
    {
        name: 'Hidden Line',
        icon: 'hidden_line',
        type: 'drawMode-hidden-line',
    },
];

const renderMode: Record<ModeFormat, OperatorType[]> = {
    '3d': ['home', 'select', 'areaSelect', 'pan', 'zoomIn', 'orbit', 'setting',
        'drawMode-shaded', 'cutting-plane', 'explode', 'clipping-commands', 'propertyPanel',
        'model-tree', 'linkedObjects', 'synchronized'],
    '2d': ['home', 'select', 'pan', 'zoomIn', 'setting', 'model-tree', 'linkedObjects', 'propertyPanel'],
    pdf: [
        'home', 'select', 'pan', 'zoomIn', 'rotateZ', 'save',
        'setting', 'plan-mode', 'document-mode', 'first-page',
        'previous-page', 'next-page', 'last-page', 'current-page'
    ],
};
const allArrBtn = [...arrBtnOperatorMain, ...arrBtnChildrenZoom, ...arrBtnChildrenOrbit, ...arrBtnChildrenRender];
export const mapArrTypeChildrenRender: OperatorType[] = arrBtnChildrenRender.map(btn => btn.type);
const mapKeyChildren: Record<KeyChildren, ButtonOperatorBase[]> = {
    zoom: arrBtnChildrenZoom,
    orbit: arrBtnChildrenOrbit,
    drawMode: arrBtnChildrenRender
}

export default class NavigatorHelper {
    static createOperatorButtonData(format: FormatViewer, viewId: ViewId, arrBtnMain: ButtonOperatorBase[] = []): ButtonOperatorBase[][] {
        const arrGroups: KeyGroupOperator[] = ['default', 'adv', 'adv1', 'adv2'];
        const arrBtnOperatorMainFinnaly = arrBtnMain.length > 0 ? arrBtnMain : createArrayBtnByFormat(format, viewId);
        const result = arrGroups.reduce<ButtonOperatorBase[][]>((pre, curr) => {
            const filterG = arrBtnOperatorMainFinnaly.filter(b => b.keyGroup === curr);
            pre.push(filterG);
            return pre;
        }, []);
        return result;
    }
    static getArrChildren(key: KeyChildren): ButtonOperatorBase[] {
        return mapKeyChildren[key];
    }
    static activeOperator(btn: ButtonOperatorBase, operatorState?: NavOperatorState): 'active' | 'default' {
        return btn.type && operatorState && operatorState.currentOperator?.includes(btn.type) ? 'active' : 'default';
    }
    static disableOperator(btn: ButtonOperatorBase, operatorState?: NavOperatorState): boolean {
        if (operatorState?.listOperatorDisable) {
            return operatorState.listOperatorDisable?.length > 0 ? operatorState.listOperatorDisable?.includes(btn.type) : false;
        }
        return false;

    }
    static changeBtnAfterSetOperator(format: FormatViewer, viewId: ViewId, btnSet: ButtonOperatorBase, previousOperator?: ButtonOperatorBase[]): ButtonOperatorBase[][] | null {
        const keyChildren = getKeyChildrenFromBtn(btnSet);
        if (keyChildren) {
            const result: ButtonOperatorBase[] = [];
            const arrBtnOperatorMainFinnaly = previousOperator || createArrayBtnByFormat(format, viewId);
            arrBtnOperatorMainFinnaly.forEach((btn: ButtonOperatorBase) => {
                let newBtn = { ...btn };
                if (btn.keyChildren === keyChildren) {
                    newBtn = { ...btn, ...btnSet };
                }
                result.push(newBtn);
            })
            return NavigatorHelper.createOperatorButtonData(format, viewId, result);
        }
        return null;
    }
    static getButtonOperator(type: OperatorType): ButtonOperatorBase | undefined {
        return allArrBtn.find(btn => btn.type === type)
    }
}

function getKeyChildrenFromBtn(btn: ButtonOperatorBase): KeyChildren | null {
    let result = null;
    Object.keys(mapKeyChildren).some(key => {
        const keyFinnaly = key as KeyChildren;
        const arrChildren = mapKeyChildren[keyFinnaly];
        if (arrChildren.map(c => c.type).includes(btn.type)) {
            result = keyFinnaly;
            return true;
        }
        return false;
    })
    return result;
}
function createArrayBtnByFormat(format: FormatViewer, viewId: ViewId): ButtonOperatorBase[] {
    let arrBtnOperatorMainFinnaly: ButtonOperatorBase[] = [];
    if (format === 'Hoops') {
        const webViewer = GlobalState.getViewer3D(viewId);
        if (webViewer) {
            const isDrawingActive = webViewer.sheetManager.isDrawingSheetActive();
            if (isDrawingActive) {
                arrBtnOperatorMainFinnaly = arrBtnOperatorMain.filter(btn => renderMode['2d'].includes(btn.type));
            } else {
                arrBtnOperatorMainFinnaly = arrBtnOperatorMain.filter(btn => renderMode['3d'].includes(btn.type));
            }
        }
    } else if (format === 'Foxit') {
        arrBtnOperatorMainFinnaly = arrBtnOperatorMain.filter(btn => renderMode['pdf'].includes(btn.type));
    }
    return arrBtnOperatorMainFinnaly
}
