/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable indent */
import { StraightLine } from "common/define";
import { AnnotRect, LineStyle, Point } from "common/type-markup";
import { RGBColor } from "react-color";
import PdfHelper from "./pdf.helper";

export default class MathHelper {
    static async getDeviceRect(pdfViewer: PdfViewer, pageIndex: number, rect: Boundary): Promise<Boundary> {
        const rectRes = await MathHelper.transformRectsArray(pdfViewer, pageIndex, [rect]);
        return rectRes[0]
    }
    static async transformRectsArray(pdfViewer: PdfViewer, pageIndex: number, rects: Boundary[]): Promise<Boundary[]> {
        const pageRender = pdfViewer.getPDFPageRender(pageIndex);
        const page = await pageRender.getPDFPage();
        return pageRender.transformRectArray(page, rects)
    }
    static getScrollPosible(refRect: Boundary, targetRect: Boundary, pageRect: Boundary): { deltaX: number, deltaY: number } {
        const { left: leftTarget, right: rightTarget, top: topTarget, bottom: bottomTarget } = targetRect;
        const targetCenter: Point2 = {
            x: (leftTarget + rightTarget) / 2,
            y: (topTarget + bottomTarget) / 2
        };
        const refPoint = { x: (refRect.right - refRect.left) / 2, y: (refRect.bottom - refRect.top) / 2 };
        let deltaX = targetCenter.x - refPoint.x;
        let deltaY = targetCenter.y - refPoint.y;
        const diffLeft = pageRect.left - refRect.left;
        const diffRight = pageRect.right - refRect.right;
        const diffTop = pageRect.top - refRect.top;
        const diffBottom = pageRect.bottom - refRect.bottom - 10;
        deltaX = refPoint.x > targetCenter.x ? Math.max(deltaX, diffLeft) : Math.min(deltaX, diffRight);
        deltaY = refPoint.y > targetCenter.y ? Math.max(deltaY, diffTop) : Math.min(deltaY, diffBottom);
        return { deltaX, deltaY };
    }
    static parseColor(color: string): number {
        if (color === null) return 0;
        try {
            return Number(color.replace('#', '0xff'));
        } catch (error) {
            return 0;
        }
    }

    static getDecimalFromColor(color: string): number {
        if (color && color.includes('#')) return parseInt(color.substring(1, color.length), 16);
        const hexColor = this.convertRGBStr2Hex(color);
        return parseInt(hexColor.substring(1, hexColor.length), 16);
    }

    static convertRGBStr2Hex(rgbStr: string): string {
        const regex = /^rgb?.\((\d+),(\d+),(\d+)/g;
        const cal = regex.exec(rgbStr);
        if (cal && cal.length === 4) {
            return MathHelper.rgbToHex(+cal[1], +cal[2], +cal[3]);
        }
        return '';
    }
    static rgbToHex(r: number, g: number, b: number): string {
        return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
    }
    static componentToHex(c: number): string {
        const hex = c.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    }
    static RGBToHexA(rgba: RGBColor): string {
        let r = rgba.r.toString(16);
        let g = rgba.g.toString(16);
        let b = rgba.b.toString(16);

        if (r.length === 1)
            r = "0" + r;
        if (g.length === 1)
            g = "0" + g;
        if (b.length === 1)
            b = "0" + b;
        return "#" + r + g + b;
    }    
    static RGBToHexX(rgba: RGBColor): string {
        let r = rgba.r.toString(16);
        let g = rgba.g.toString(16);
        let b = rgba.b.toString(16);

        if (r.length === 1)
            r = "0" + r;
        if (g.length === 1)
            g = "0" + g;
        if (b.length === 1)
            b = "0" + b;
        return "0x" + r + g + b;
    }

    static async getSizeRatio(pdfViewer: PdfViewer) {
        return await MathHelper.sizeRatio(pdfViewer, PdfHelper.pageCurrentIndex);
    }

    static async sizeRatio(pdfViewer: PdfViewer, currentPage: number): Promise<number> {
        const pageRender = pdfViewer.getPDFPageRender(currentPage);
        let resRatio = 1;
        let refRatio = 1;
        if (pageRender) {
            const pdfPage = await pageRender.getPDFPage();
            const a4Witdth = 210;
            const a4Height = 297;
            const pdfWidth = pdfPage.getPxWidth();
            const pdfHeight = pdfPage.getPxHeight();
            const ratioWidth = pdfWidth / a4Witdth;
            const ratioHeight = pdfHeight / a4Height;
            resRatio = ratioWidth > ratioHeight ? ratioWidth : ratioHeight;
            refRatio = 3.8857142857142857;
        }
        return resRatio / refRatio;
    }

    public static calcCirclePath(point1: Point, point2: Point, point3: Point) {
        const distPoint3_2 = MathHelper.dist(point3, point2);
        const distPoint2_1 = MathHelper.dist(point2, point1);
        const distPoint1_3 = MathHelper.dist(point1, point3);

        const angle = Math.acos((distPoint3_2 * distPoint3_2 + distPoint2_1 * distPoint2_1 - distPoint1_3 * distPoint1_3) / (2 * distPoint3_2 * distPoint2_1));

        // calc radius of circle
        const K = 0.5 * distPoint3_2 * distPoint2_1 * Math.sin(angle);
        let radius = distPoint3_2 * distPoint2_1 * distPoint1_3 / 4 / K;
        radius = Math.round(radius * 1000) / 1000;

        const largeFlag = +(Math.PI / 2 > angle);

        const sweepFlag = +((point3.x - point1.x) * (point2.y - point1.y) - (point3.y - point1.y) * (point2.x - point1.x) < 0);

        return ['M', point1.x, point1.y, 'A', radius, radius, 0, largeFlag, sweepFlag, point3.x, point3.y].join(' ');
    }

    public static dist(point1: Point, point2: Point) {
        return Math.sqrt(Math.abs(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)));
    }
    public static distFromString(startPoint: string, endPoint: string) {
        const point1 = new Communicator.Point2(
            Number(startPoint.substring(0, startPoint.indexOf(','))),
            Number(startPoint.substring(startPoint.indexOf(',') + 1)));
        const point2 = new Communicator.Point2(
            Number(endPoint.substring(0, endPoint.indexOf(','))),
            Number(endPoint.substring(endPoint.indexOf(',') + 1)));
        return Math.round(MathHelper.dist(point1, point2) * 100 / 75) / 100;
    }
    public static convertPdfPoint(px: number, scale: number) {
        return px / 72 * 96 * scale;
    }

    public static getReverseDevicePoint(point: Point, scale: number, pdfPage: any): Point {
        const pointTranslate = [point.x, point.y];
        const pointArr = pdfPage.reverseDevicePoint(pointTranslate, scale, 0);
        return { x: pointArr[0], y: pointArr[1] };
    }

    public static getReverseDeviceRect(rect: AnnotRect, scale: number, pdfPage: any): AnnotRect {
        return pdfPage.reverseDeviceRect(rect, scale, 0);
    }

    public static getCurrentTimeString(time: Date) {
        const yyyy = time.getFullYear();
        const mm = time.getMonth() + 1;
        const dd = time.getDate();
        const hh = time.getHours();
        const min = time.getMinutes();
        const ss = time.getSeconds();
        return [yyyy, (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, hh, min, ss].join('');
    }

    public static getOffsetUTCTime(time: Date) {
        const offsetTime = -1 * time.getTimezoneOffset();
        const offsetHour = Math.floor(offsetTime / 60);
        const offsetMin = offsetTime - offsetHour * 60;
        return [MathHelper.toLocalString(offsetHour, 2), MathHelper.toLocalString(offsetMin, 2)].join('\'');
    }
    public static toLocalString(num: number, digits: number) {
        return num.toLocaleString('en-US', { minimumIntegerDigits: digits, useGrouping: false });
    }

    static is2RectCollide(rect1: Rectangle, rect2: Rectangle) {
        const result = (rect1.x < rect2.x + rect2.width) &&
            (rect1.x + rect1.width > rect2.x) &&
            (rect1.y < rect2.y + rect2.height) &&
            (rect1.y + rect1.height > rect2.y)
        return result
    }

    static pointLineCollide(point: Point, lineStartPoint: Point, lineEndPoint: Point, lineWeight: number) {
        const dist1 = MathHelper.dist(point, lineStartPoint);
        const dist2 = MathHelper.dist(point, lineEndPoint);
        const dist = dist1 + dist2;
        const lineLength = MathHelper.dist(lineStartPoint, lineEndPoint);
        const check = Math.abs(dist - lineLength) < lineWeight * 0.4;
        return check;
    }

    static getEllipseEquation(mousePos: Point, center: Point, xRadius: number, yRadius: number, lineWeight: number): number {
        const { x, y } = mousePos;
        const equation = Math.pow(x - center.x + lineWeight, 2) / Math.pow(xRadius, 2) + Math.pow(y - center.y + lineWeight, 2) / Math.pow(yRadius, 2);
        return equation;
    }

    static parsePointString(str: string): number {
        return Number(str.replace('px', ''));
    }
    static createMouseRect(mousePos: Point, deadZone = 0): Rectangle {
        const rect: Rectangle = {
            x: Math.max(mousePos.x - deadZone, 0),
            y: Math.max(mousePos.y - deadZone, 0),
            width: deadZone * 2,
            height: deadZone * 2,
        }
        return rect;
    }
    static applyLineStyle(canvasContext: CanvasRenderingContext2D, lineStyle: LineStyle) {
        switch (lineStyle) {
            case LineStyle.largeDash:
                canvasContext.setLineDash([25, 25]);
                break;
            case LineStyle.smallDash:
                canvasContext.setLineDash([12, 25]);
                break;
            case LineStyle.center:
                canvasContext.setLineDash([25, 15, 5, 15]);
                break;
            case LineStyle.phantom:
                canvasContext.setLineDash([25, 15, 5, 15, 5, 15]);
                break;
            default:
                canvasContext.setLineDash([]);
        }
    }

    static communicatorColorToRGBAString(color: Communicator.Color, opacity = 0) {
        if (!color) return null;

        return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`;
    }
    static worldPointToScreenPoint(
        viewer: Communicator.WebViewer,
        worldPosition: Point3,
    ): Communicator.Point2 {
        const wp4 = new Communicator.Point4(worldPosition.x, worldPosition.y, worldPosition.z, 1);
        const sp4 = new Communicator.Point4(0, 0, 0, 0);

        viewer.view.getFullCameraMatrix().transform4(wp4, sp4);

        const invW = 1 / sp4.w;
        const screenPosition = new Communicator.Point2(sp4.x * invW, sp4.y * invW);

        const dims = viewer.model.getClientDimensions();
        const w = dims[0];
        const h = dims[1];

        screenPosition.x = 0.5 * w * (screenPosition.x + 1);
        screenPosition.y = 0.5 * h * (screenPosition.y + 1);

        screenPosition.x = Math.max(0, Math.min(screenPosition.x, w));
        screenPosition.y = h - Math.max(0, Math.min(screenPosition.y, h));

        return screenPosition;
    }

    static parseDataToPoint3(point: any): Communicator.Point3 {
        const point3 = new Communicator.Point3(point.x, point.y, point.z);
        return point3;
    }

    static calculateNotePosition(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2, noteSize: number): Point {
        if (firstPoint.x >= secondPoint.x) {
            if (firstPoint.y >= secondPoint.y) {
                return {
                    x: firstPoint.x,
                    y: firstPoint.y
                }
            }
            else {
                return {
                    x: firstPoint.x,
                    y: firstPoint.y - noteSize
                }
            }
        }
        else {
            if (firstPoint.y >= secondPoint.y) {
                return {
                    x: firstPoint.x - noteSize,
                    y: firstPoint.y
                }
            }
            else {
                return {
                    x: firstPoint.x - noteSize,
                    y: firstPoint.y - noteSize
                }
            }
        }
    }

    static getPointsEquilateralPolygon(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2, edgeNumber: number) {
        const degreeEdge = 360 / edgeNumber;
        const pt1 = firstPoint;
        const pt2 = secondPoint;
        const pt13D = new Communicator.Point3(pt1.x, pt1.y, 0);
        const pt23D = new Communicator.Point3(pt2.x, pt2.y, 0);
        const transVec = Communicator.Point2.subtract(pt1, Communicator.Point2.zero());
        const vecX = pt23D.subtract(pt13D);
        const rotMax = Communicator.Matrix.zAxisRotation(degreeEdge);
        const pnts: Communicator.Point2[] = [];
        pnts.push(new Communicator.Point2(vecX.x, vecX.y));
        let vecTemp = vecX.copy();
        for (let i = 1; i < edgeNumber; i++) {
            const vecRes = rotMax.transform(vecTemp);
            const newPt = Communicator.Point3.zero().add(vecRes);
            pnts.push(new Communicator.Point2(newPt.x, newPt.y));
            vecTemp = vecRes.copy();
        }
        const pointList = pnts.map(v => {
            const screenPoint = new Communicator.Point2(v.x, v.y);
            return screenPoint.add(transVec)
        })
        return pointList;
    }

    static drawLineCap(points: Communicator.Point2[], ctx: CanvasRenderingContext2D) {
        if (points.length === 0) return;
        ctx.beginPath();
        ctx.setLineDash([]);
        ctx.lineCap = 'square';
        ctx.lineJoin = 'miter';
        points.forEach((v, i) => {
            if (i === 0) return ctx.moveTo(v.x, v.y);
            return ctx.lineTo(v.x, v.y);
        })
        ctx.stroke();
    }
    static calVector(vec: Communicator.Point2, angle: number) {
        const newVec = new Communicator.Point2(
            vec.x * Math.cos(angle) - vec.y * Math.sin(angle),
            vec.x * Math.sin(angle) + vec.y * Math.cos(angle),
        );
        return newVec;
    }

    // Collision detection
    static get4LineOfRect(minPt: Communicator.Point2, maxPt: Communicator.Point2): StraightLine[] {
        const width = Math.abs(minPt.x - maxPt.x);
        const height = Math.abs(minPt.y - maxPt.y);
        const listPoint = [
            new Communicator.Point2(minPt.x, minPt.y),
            new Communicator.Point2(minPt.x + width, minPt.y),
            new Communicator.Point2(minPt.x + width, minPt.y + height),
            new Communicator.Point2(minPt.x, minPt.y + height),
        ];
        return MathHelper.getArrStraightLine(listPoint);
    }

    static getArrStraightLine(listPoint: Communicator.Point2[], withLastLine = false): StraightLine[] {
        const listLine = listPoint.map((v, i) => {
            if (i === listPoint.length - 1) return { startPoint: listPoint[i], endPoint: listPoint[0] } as StraightLine;
            return { startPoint: listPoint[i], endPoint: listPoint[i + 1] } as StraightLine;
        });
        if (!withLastLine) listLine.pop();
        return listLine;

    }
    static getRectFrom2Points(minPt: Communicator.Point2, maxPt: Communicator.Point2): Rectangle {
        const width = Math.abs(minPt.x - maxPt.x);
        const height = Math.abs(minPt.y - maxPt.y);
        const rect: Rectangle = {
            x: minPt.x,
            y: minPt.y,
            width: width,
            height: height,
        }
        return rect;
    }

    static pointRectCollide(p: Communicator.Point2, rect: Rectangle) {
        const rect1 = MathHelper.createMouseRect(p);
        return MathHelper.is2RectCollide(rect1, rect);
    }
    static lineLineCollide(p1: Communicator.Point2, p2: Communicator.Point2, p3: Communicator.Point2, p4: Communicator.Point2) {
        const uA = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / ((p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y));
        const uB = ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) / ((p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y));
        const isCollide = (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1);
        return isCollide;
    }

    static getRotationDegreeFromString(str: string): number {
        return Number(str.replace('rotate(', '').replace('deg)', '').toString());
    }

    static calAvgFromArray(arr: number[]): number {
        return arr.reduce((a, b) => a + b, 0) / arr.length;
    }

    static calDashString(start: string, repeat: string, time: number) {
        let dashString = start;
        Array(time).fill(0).forEach(v => dashString += ` ${repeat}`);
        return dashString;
    }

    static getCursorString(rotation: number, fallback: string): string {
        const str = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32"><path d="M 16,5 L 12,10 L 14.5,10 L 14.5,22 L 12,22 L 16,27 L 20,22 L 17.5,22 L 17.5,10 L 20, 10 L 16,5 Z" stroke-linejoin="round" stroke-width="1.2" fill="white" stroke="black" style="transform:rotate(${rotation}deg);transform-origin: 16px 16px"></path></svg>')16 16,${fallback}`;
        return str;
    }

    static getRotateCursor(): string {
        return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 1200 1200" xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="padding:2px;filter: drop-shadow(0 1px 0 antiquewhite) drop-shadow(1px 0 0 antiquewhite) drop-shadow(-1px 0 0 antiquewhite) drop-shadow(0 -1px 0 antiquewhite)"><path d="M405.958,81.303c-15.021-17.78-32.731-33.265-52.6-45.852C316.754,12.259,274.402,0,230.884,0 C169.685,0,112.149,23.832,68.875,67.106C25.601,110.38,1.769,167.916,1.769,229.114S25.601,347.85,68.875,391.123 c43.274,43.273,100.81,67.105,162.009,67.105c47.021,0,92.212-14.145,130.688-40.906c37.567-26.131,66.195-62.395,82.788-104.87 l-52.094-20.351c-26.149,66.943-89.498,110.199-161.384,110.199c-95.496,0-173.188-77.691-173.188-173.188 S135.387,55.925,230.883,55.925c48.337,0,93.034,19.639,125.137,53.303l-60.872,34.043l79.391,47.296l79.392,47.299l1.263-92.403 l1.267-92.403L405.958,81.303z"/></svg>'),crosshair`;
    }

    static rotatePoint(point: Communicator.Point2, center: Communicator.Point2, radians = 0): Communicator.Point2 {
        const x = (point.x - center.x) * Math.cos(radians) - (point.y - center.y) * Math.sin(radians) + center.x;
        const y = (point.x - center.x) * Math.sin(radians) + (point.y - center.y) * Math.cos(radians) + center.y;
        return new Communicator.Point2(x, y);
    }

    static convertPointWithRotation(originPos: Communicator.Point2, center: Communicator.Point2, rotation: number, offset = 0) {
        const radAngle = rotation * Math.PI / 180;
        const vec = originPos.copy().subtract(center.copy());
        const vec1 = MathHelper.calVector(vec, radAngle);
        const pos = center.copy().add(vec1.scale(1));
        const point = new Communicator.Point2(pos.x - offset / 2, pos.y - offset / 2);
        return point;
    }

    static polyPointCollide(verties: Communicator.Point2[], point: Communicator.Point2): boolean {
        let collision = false;
        verties.forEach((v, index) => {
            const i = (index + 1 === verties.length) ? 0 : index + 1;
            const vc = verties[index];
            const vn = verties[i];
            const px = point.x;
            const py = point.y;
            if (((vc.y >= py && vn.y < py) || (vc.y < py && vn.y >= py)) &&
                (px < (vn.x - vc.x) * (py - vc.y) / (vn.y - vc.y) + vc.x)) {
                collision = !collision;
            }
        });
        return collision;
    }
    // calc 4 attach points for callout
    static getAttachPoint(p1: Communicator.Point2, firstPoint: Communicator.Point2, size: Communicator.Point2, bdCenter: Communicator.Point2): Communicator.Point2 {
        const possibleAttachPoints = [
            new Communicator.Point2(
                p1.x + size.x / 2,
                p1.y),
            new Communicator.Point2(
                p1.x + size.x,
                p1.y + size.y / 2),
            new Communicator.Point2(
                p1.x + size.x / 2,
                p1.y + size.y),
            new Communicator.Point2(
                p1.x,
                p1.y + +size.y / 2)
        ];
        const attachPoint = possibleAttachPoints.reduce((prev, curr) =>
            Communicator.Point2.distance(prev, bdCenter) + Communicator.Point2.distance(prev, firstPoint) <
                Communicator.Point2.distance(curr, bdCenter) + Communicator.Point2.distance(curr, firstPoint)
                ? prev : curr
        );
        return attachPoint;
    }

    static getMaxMin(arr: Communicator.Point2[]) {
        const maxX = Math.max(...arr.map(v => v.copy().x));
        const maxY = Math.max(...arr.map(v => v.copy().y));
        const minX = Math.min(...arr.map(v => v.copy().x));
        const minY = Math.min(...arr.map(v => v.copy().y));
        const max = new Communicator.Point2(maxX, maxY);
        const min = new Communicator.Point2(minX, minY);
        return { min, max };
    }

    static mod(num: number, divider: number): {
        division: number,
        reminder: number
    } {
        const division = Math.floor(num / divider);
        const reminder = num % divider
        const result = {
            division: division,
            reminder: reminder
        }
        return result
    }

    static getPositiveRootNode(viewer: Communicator.WebViewer) {
        return 0;
    }
}