/* eslint-disable */
import clsx from 'clsx';
import { PropsIcon, SvgSizeNumber } from 'common/define';
import { IconName } from 'common/define-name-icon';
import { CTooltip } from 'components/CTooltip';
import useStyleTheme from 'hooks/useStyleTheme';
import React from 'react';
import SVG from 'react-inlinesvg';
import { createStyles, ThemeDefine } from 'theme/ThemeProvider';

const fillBlackList: IconName[] = ['zoomwindow', 'planeX', 'planeY', 'planeZ','SectionToggle', 'planeMinusX', 'planeMinusY', 'planeMinusZ', 'Visibility'];
type PropsUseTheme = Pick<PropsIcon, 'colorSvg'| 'svgSize' | 'disabled'>;
const getColor = (theme: ThemeDefine, props: PropsUseTheme) => {
    if (props.disabled) {
        return theme.palette.disabled;
    }
    if (props.colorSvg) {
        const color = theme.palette[props.colorSvg];
        if (color) {
            return color;
        } else if (typeof props.colorSvg === 'string' && props.colorSvg.charAt(0) === '#') {
            return props.colorSvg;
        }
    }
};

const styles = createStyles((theme: ThemeDefine) => ({
    root: {
        display: "flex",
        cursor: 'pointer',
    },
    hover: {
        '&:hover': {
            background: theme.palette.lightgray2,
        }
    },
    activeStyle: {
        '& rect,circle,line,polygon,polyline': {
            stroke: theme.palette.light,
        },
        '& path': {
            fill: theme.palette.light,
        },
        background: theme.palette.primary,
    },
    activeStyle2: {
        '& rect,circle,line,polygon,polyline': {
            stroke: theme.palette.primary,
        },
        '& path': {
            fill: theme.palette.primary,
        },
        background: theme.palette.lightgray3,
    },
    disableStyle: {
        '& rect,circle,line,polygon,polyline': {
            stroke: theme.palette.gray,
        },
        '& path': {
            fill: theme.palette.gray,
        },
        background: theme.palette.lightgray,
    },
    colorIcon: {
        '& > svg': {
            '& rect,circle,line,polygon,polyline': {
                stroke: (props: PropsUseTheme) => getColor(theme, props)
            },
            '& path': {
                fill: (props: PropsUseTheme) => getColor(theme, props)
            }
        }
    },
    sizeIcon: {
        '& > svg':  {
            lineHeight: (props: PropsUseTheme) => `${SvgSizeNumber[props.svgSize!]}`,
            height: (props: PropsUseTheme) => `${SvgSizeNumber[props.svgSize!]}`,
            width: (props: PropsUseTheme) => `${SvgSizeNumber[props.svgSize!]}`,
        }
    },
    disabled: {
        pointerEvents: 'none'
    }
}));
function useIconSvg(nameSvg: string) {
    return (
        <SVG 
            src={`${process.env.PUBLIC_URL}/assets/icons/${nameSvg}.svg`}/>
    )
}

const CIconSvgComponent =  React.forwardRef(({
    name, 
    tooltip, 
    canHover, 
    activeStyle, 
    disableStyle, 
    dropdownIcon, 
    borderStyle,
    colorIndicator,
    cornerIcon,
    overrideFill = true,
    ...props}: PropsIcon): JSX.Element | null => {
    const iconSvg = useIconSvg(name ?? '');
    const triangleIcon =  useIconSvg('moreicon');
    const downIcon = useIconSvg('VectorDown')
    const {svgSize = 'default', disabled, rotateDeg} = props;
    const colorFinal = props.colorSvg ?? props.hexColor ?? 'default';
    const classes = useStyleTheme(styles, {colorSvg: colorFinal, svgSize, disabled});
    if (!iconSvg) {
        return null
    }
   
    const stylesEx = rotateDeg ? {transform: `rotate(${rotateDeg}deg)`, ...props.style}: props.style;
    
    const activeClass = activeStyle ? classes.activeStyle2 :
        {
            [classes.colorIcon]: props.colorSvg !== 'origin' && 
                (name && !fillBlackList.includes(name) || props.colorSvg === 'light'), 
            [classes.disabled]: disabled
        };

    const divResult = (
        <div
            className={clsx(
                dropdownIcon && canHover && !activeStyle && classes.hover,
                dropdownIcon && activeClass,
                `relative flex items-center ${colorIndicator !== undefined && 'px-1'} ${borderStyle && 'border border-gray-200'}`,
                disableStyle && classes.disableStyle
            )} 
            onClick={props.onClick}>
            <div className={`flex flex-col `}>
                <div 
                    id={props.id}
                    className={clsx(
                        classes.root,
                        overrideFill && activeClass,
                        `flex items-center p-1 rounded-sm relative mb-0.5`,
                        props.className,
                        classes.sizeIcon,
                        !dropdownIcon && canHover && !activeStyle && classes.hover,
                        disableStyle && classes.disableStyle
                    )}
                    style={stylesEx}>
                    {iconSvg}
                </div>
                {
                    colorIndicator !== undefined && 
                    <div 
                        style={colorIndicator ? 
                            {backgroundColor:`rgb(${colorIndicator.r},${colorIndicator.g},${colorIndicator.b})`} : 
                            {
                                backgroundImage: `linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, 	transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)`,
                                backgroundSize: `4px 4px`,
                                backgroundPosition: `0 0, 0 2px, 2px -2px, -2px 0px`,
                            }
                        }
                        className={clsx(
                            classes.root,
                            'flex-center !h-1 w-2/5 rounded-lg !absolute bottom-1 ml-1',
                            props.className,
                            classes.sizeIcon,
                            disableStyle && classes.disableStyle
                        )}></div>
                }
            </div>
            {
                dropdownIcon && 
                <div className="flex-center !w-2.5 !h-auto z-10">
                    <div 
                        id={props.id}
                        className={clsx(
                            classes.root,
                            activeClass,
                            `flex-center rounded-sm p-1 mr-3 !bg-transparent pointer-events-none`,
                            props.className,
                            classes.sizeIcon,
                        )}
                        style={{...stylesEx,height: '18px', width:'18px'}}>
                        {downIcon}
                    </div>
                </div>
            }
            {
                cornerIcon && 
                <div 
                    id={props.id}
                    className={clsx(
                        activeClass,
                        `p-1 pointer-events-none !absolute right-0 -bottom-1`,
                        props.className,
                        classes.sizeIcon,
                    )}
                    style={{...stylesEx,height: '18px', width:'18px', background: 'transparent'}}>
                    {triangleIcon}
                </div>
            }
        </div>
    );
    if (tooltip?.title) {
        const placement = tooltip.placement ?? 'top';
        return (
            <CTooltip title={tooltip.title} placement={placement}>
                {divResult}
            </CTooltip>
        )
    }
    return divResult
});

const CIconSvg = React.memo(CIconSvgComponent);
export default CIconSvg;