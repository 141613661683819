/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPinMarkerData, SettingState } from 'common/define';
import { GlobalState } from 'common/global';
import { MarkupPinMarkerJson, MarkupViewEntities } from 'common/type-markup';
import { selectionFlag } from 'container/viewer3d/extends/CSelectionManager';
import ModelHelper from 'container/viewer3d/model/model.helper';
import { mapOperatorTypeDrawMode } from 'container/viewer3d/operator/operator3D.helper';
import Utils from 'utils/utils';
import { MarkupBaseBounding } from '../markup-canvas-elements/markup.bounding.element';
import { PinMarkerElementCanvas } from '../markup-canvas-elements/markup.pinmarker-canvas.element';
import { MarkupBaseItem } from '../markup-items/markup.base.item';

export class MarkupPinMarkerItem extends MarkupBaseItem {
    public _firstPoint: Communicator.Point3 = Communicator.Point3.zero();
    private _uniqueId: string;
    private pinMarkerHTML: PinMarkerElementCanvas;
    private originalDragPoint: Communicator.Point3 | null = null;
    private _pinMarkerData: IPinMarkerData;
    private _isNodeHighlighed = false;
    private _isLastSelected = false;
    private _markupView: MarkupViewEntities | null = null;
    public constructor(
        viewer: Communicator.WebViewer,
        pinMarkerData: IPinMarkerData,
    ) {
        super(viewer);
        this.iconName = 'pushpin';
        this.shapeName = 'Pin Marker';
        this._uniqueId = this.uniqueId;
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setCanRotate(false);
        this.redlineBounding.setGripDirection([]);
        this.redlineBounding.borderRounded = true;
        this.redlineBounding.boundingTransparent = true;
        this.redlineBounding.enableTooltip = true;
        this.isMarkup3D = true;
        this._pinMarkerData = pinMarkerData;
        this.pinMarkerHTML = new PinMarkerElementCanvas(pinMarkerData);
        this.redlineBounding.setBoudingboxCallback(
            (point: Communicator.Point2) => this.onDragStart(point),
            (point: Communicator.Point2) => this.onDragMove(point),
            (point: Communicator.Point2) => this.onBoundingBoxDragEndCallback(point),
        );
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2) => this.onBoundingClickCallback(),
        );
        this.redlineBounding.createBoundingBox();
        this.redlineBounding.padding = 15;
    }
    private _update(): void {
        if (this.redlineBounding) this.redlineBounding.setTooltipContent(this._pinMarkerData);
        const { view } = this._viewer;
        const firstPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._firstPoint.copy()),
        );
        const secondPoint = firstPoint.copy();

        this.pinMarkerHTML.setFirstPoint(firstPoint);
        this.pinMarkerHTML.getPinMarkerCanvas();
        this._isReady = true;

        this.updateBoundingBox(firstPoint, secondPoint);
    }
    public draw(): void {
        this._update();
        if (!this.isHiding) {
            if (this._isReady) {
                this._redlineElementId = this._viewer.markupManager.addMarkupElement(
                    this.pinMarkerHTML.getPinMarkerCanvas(),
                );
            }
        } else if (this._redlineElementId) {
            this._viewer.markupManager.removeMarkupElement(this._redlineElementId);
            this._redlineElementId = null;
        }

        this.handleBoundingRectInteraction(() => {
            if (!this.boundingIds) return;
        });
    }

    updateBoundingBox(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
        if (!firstPoint || !secondPoint) return;
        const pos = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y))
        const size = this.pinMarkerHTML.getSize();
        const stroke = this.pinMarkerHTML.getStrokeWidth();
        const boxPos = new Communicator.Point2(pos.x - stroke / 2, pos.y - stroke / 2);
        if (this.redlineBounding) {
            this.redlineBounding.setPosition(boxPos);
            this.redlineBounding.setSize(new Communicator.Point2(size.x + stroke, size.y + stroke));
        }
    }
    public getClassName(): string {
        return "Communicator.Markup.MarkupPinMarkerItem";
    }
    setFirstPoint(point: Communicator.Point3) {
        if (point) {
            this._firstPoint = point;
        }
    }
    isSelectedByRectangle(pt1: Communicator.Point2, pt2: Communicator.Point2, withIn: boolean): boolean {
        return false
    }
    toJson(): MarkupPinMarkerJson {
        const circleObj = {
            className: this.getClassName(),
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            startPoint: this._firstPoint.copy(),
            uniqueId: this.uniqueId,
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
            is3DMarkup: true,
            pinMarkerData: this._pinMarkerData,
            markupView: this._markupView,
        };
        return circleObj;
    }
    fromJson(data: any): void {
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        this._firstPoint = data.startPoint;
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
    }

    onDragStart(point: Communicator.Point2) {
        if (!this._isCanEdit) return false;
        this.isUpdate = false;
        Utils.getPickSelectionPoint(point, this._viewer).then(selection => {
            if (selection) {
                this._previousDragPlanePosition.assign(selection);
                this.originalDragPoint = selection
            }
        })
        return !1;
    }

    onDragMove(point: Communicator.Point2) {
        if (!this._isCanEdit) return false;
        this.isUpdate = true;
        Utils.getPickSelectionPoint(point, this._viewer).then(selection => {
            if (selection) {
                this._firstPoint.assign(selection)
                this._previousDragPlanePosition.assign(selection);
                this.pinMarkerHTML.setDisabledStyle(true);
            } else {
                const a = this._viewer.view;
                const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
                if (!b) return
                this._firstPoint.assign(b);
                this.pinMarkerHTML.setDisabledStyle(false);
            }
            this._viewer.markupManager.refreshMarkup();
        })
        return !0;
    }

    onBoundingBoxDragEndCallback(point: Communicator.Point2) {
        if (this.pinMarkerHTML.isEnabled) {
            this.triggerUpdatePinMarker();
            this.isUpdate = false;
        } else {
            if (this.originalDragPoint) {
                this._firstPoint.assign(this.originalDragPoint);
                this.pinMarkerHTML.setDisabledStyle(true);
                this._viewer.markupManager.refreshMarkup();
            }
        }
    }

    triggerUpdatePinMarker() {
        GlobalState.triggerUpdatePinMarker$.next(this);
    }

    startGripPointDragMoveCallback(point: Communicator.Point2, isForcing: boolean) {
        if (!this._isCanEdit) return;
        this.isUpdate = true;
        const { view } = this._viewer;
        const pos = point;

        this._firstPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(pos, view)!);
        this._viewer.markupManager.refreshMarkup();
    }

    async onBoundingClickCallback() {
        const fncClick = async () => {
            const { nodes } = this._pinMarkerData;
            this._viewer.selectionManager.addFlag(selectionFlag.SelectByLink);
            this._viewer.selectPart(null);

            if (!this._isNodeHighlighed) {
                nodes.forEach(node => this._viewer.selectPart(node, Communicator.SelectionMode.Toggle));
                GlobalState.toggleHighlightAllPinMarker(this._viewer, false);
                GlobalState.pinMarkerActive$.next(this);
                this._viewer.model.setInstanceModifier(
                    Communicator.InstanceModifier.DoNotXRay,
                    [],
                    true
                )
                this._viewer.view.setDrawMode(Communicator.DrawMode.Ghost);
                await this.handleActivateMarkupView();
                const setting: SettingState | null = Utils.getValueLocalStorage('setting')
                if (setting) {
                    ModelHelper.applyXrayOpacity(this._viewer, setting.general.xRayOpacity, setting.general.ghostingOpacity)
                }
            } else {
                const type = GlobalState.mapCurrentDrawMode.get(this._viewer);
                const mode = mapOperatorTypeDrawMode[type?? 'drawMode-shaded'];
                if (mode !== undefined) {
                    if (mode !== Communicator.DrawMode.XRay) {
                        this._viewer.view.setDrawMode(mode);
                        if (mode === Communicator.DrawMode.HiddenLine) {
                            const { top, bottom } = this._viewer.view.getBackgroundColor();
                            ModelHelper.configHiddenLineMode(this._viewer, top, bottom)
                        }
                    } else {
                        const rootNodeId = ModelHelper.getRootNodeId(this._viewer);
                        if (rootNodeId !== null) {
                            this._viewer.model.setInstanceModifier(
                                Communicator.InstanceModifier.DoNotXRay,
                                [rootNodeId],
                                false
                            );
                            this._viewer.view.setDrawMode(mode);
                        }
                    }
                }
                GlobalState.pinMarkerActive$.next(null);
            }
            this.toggleActiveBorder(!this._isNodeHighlighed);
        }
        GlobalState.onPinMarkerClick$.next(fncClick);
    }

    async handleActivateMarkupView() {
        if (!this._markupView) return Promise.resolve();
        await this._viewer.markupManager.activateMarkupViewWithPromise(this._markupView.uniqueId);
    }

    hit(point: Communicator.Point2) {
        return this.pinMarkerHTML.hit(point);
    }
    setMarkupVisible(visible: boolean): void {
        this.pinMarkerHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        super.setMarkupVisible(visible);
    }
    getHTMLElement() {
        return this.pinMarkerHTML.baseCanvas;
    }
    setSelected(vis: boolean) {
        super.setSelected(true);
    }
    toggleActiveBorder(isActive: boolean): void {
        this.pinMarkerHTML.toggleActiveBorder(isActive);
        this._isNodeHighlighed = isActive;
    }
    public get pinMarkerData(): IPinMarkerData {
        return this._pinMarkerData;
    }
    getPinMarkerData(): IPinMarkerData {
        return this._pinMarkerData;
    }

    public set markupView(view: MarkupViewEntities) {
        this._markupView = view;
    }
}
