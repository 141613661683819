/* eslint-disable no-useless-rename */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BimApi } from "api/bim.api";
import CMICPropertyApi from "api/cmicProperty.api";
import { CMICPropertyItem, FileExtension, MapValueProperties, TabTreeViewsKeys, ValueProperties } from "common/define";
import { GlobalState } from "common/global";
import { RootEpic } from "common/type-state";
import { concat, merge, of, zip } from "rxjs";
import { catchError, concatMap, distinctUntilChanged, filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import Utils, { Lodash } from "utils/utils";
import { PropertiesHelper } from "./helper";

interface CMICProperty {
    costCode: string[],
    costType: string[],
}
export interface PropertiesState {
    currentMapProperties: MapValueProperties | undefined;
    loadingProperties: boolean;
    cmicProperty: CMICProperty;
    cmicLoading: boolean;
    preObjectProperties?: {nodes: number[], hoopsProps: any[]}
}

const initState: PropertiesState = {
    currentMapProperties: undefined,
    loadingProperties: false,
    cmicProperty: {
        costCode: [],
        costType: [],
    },
    cmicLoading: false,
};

const propertiesSlice = createSlice({
    name: 'properties',
    initialState: initState,
    reducers: {
        setProperties(state, action: PayloadAction<MapValueProperties | undefined>) {
            state.currentMapProperties = action.payload;
            state.loadingProperties = false
        },
        getPropertiesFromViews(state, action: PayloadAction<string | undefined>) {
            state.loadingProperties = true
        },
        clearPropertiesData(state) {
            state.loadingProperties = false;
            state.currentMapProperties = undefined
        },
        setMapPropertiesByKey(state, action: PayloadAction<{ viewId: ViewId, tabKey: TabTreeViewsKeys, value: ValueProperties[], prePayload: {nodes: number[], hoopsProps: any[]} }>) {
            const { viewId, tabKey, value, prePayload } = action.payload;

            const newMap = PropertiesHelper.setMapProperties(viewId, tabKey, value);
            state.currentMapProperties = newMap;
            state.loadingProperties = false;
            state.preObjectProperties = prePayload;
        },
        clearPropertiesDataByKey(state, action: PayloadAction<{ viewId: ViewId, tabKey: TabTreeViewsKeys }>) {
            const { viewId, tabKey } = action.payload;
            const newMap = PropertiesHelper.setMapProperties(viewId, tabKey, []);
            state.currentMapProperties = newMap;
            state.loadingProperties = false;
            state.preObjectProperties = undefined;
        },
        setLoadingProperties(state, action: PayloadAction<boolean>) {
            state.loadingProperties = action.payload
        },
        updatePropByViewIdAtive(state, action: PayloadAction<ViewId>) { return },
        setPropFormMultiNode(state, action: PayloadAction<{nodes: number[], hoopsProps: any[]}>) { 
            const { nodes } = action.payload;
            state.loadingProperties = !Lodash.isEqual(state.preObjectProperties, action.payload) && nodes.length > 0;
        },
        getCostProperty(state, action: PayloadAction<undefined>) { return },
        setCmicProperty(state, action: PayloadAction<CMICProperty>) {
            state.cmicProperty = action.payload;
        },
        setCMICLoading(state, action: PayloadAction<boolean>) {
            state.cmicLoading = action.payload;
        },
    }
})



/** epics */
const updatePropByViewIdAtive$: RootEpic = (action$) => action$.pipe(
    filter(updatePropByViewIdAtive.match),
    switchMap(action => {
        const viewIdActive = action.payload;
        const getCurrentProp = GlobalState.mapPropertiesData.get(viewIdActive);
        return [propertiesSlice.actions.setProperties(getCurrentProp)]
    })
)

const setPropFormMultiNode$: RootEpic = (action$, state$) => action$.pipe(
    filter(setPropFormMultiNode.match),
    distinctUntilChanged((pre, curr) => Lodash.isEqual(pre.payload, curr.payload)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const {nodes, hoopsProps} = action.payload;
        const extension = state.multiViewer.viewActive.extension.toLowerCase()
        const viewIdActive = state.multiViewer.viewActive.viewId;
        const viewActive = state.multiViewer.viewActive;
        const viewIdFinal = GlobalState.getViewId(viewIdActive);
        // const fileList = state.filesList.filesList;
        const viewer = GlobalState.getViewer3D(viewIdActive);
        if (!viewer) return [setLoadingProperties(false)];

        const arrNodeSelectedTrue: number[] = [];
        // const ViewIdModelTree = Utils.isCaseMultiStreamUseOneTree(
        //     viewActive.viewId,
        //     `.${state.multiViewer.viewActive.extension}`
        // );

        // if (viewIdActive !== viewIdFinal && viewActive.extension === FileExtension.Revit) { // linking 2d
        //     const {
        //         modelFileId: mainModelId,
        //         fileInfo: fileInfo,
        //     }
        //         = Utils.getModelFileIdFromViewId(ViewIdModelTree, fileList);
        //     const persistentMap = Viewer3DHelper.getPersistentIdsMapInNodeIds(viewer, viewActive.viewId, nodes);
        //     if (!mainModelId) return []
        //     persistentMap.forEach((listPersistentNodes, baseNodeId) => {
        //         for (let index = 0; index < listPersistentNodes.length; index++) {
        //             const persistentNode = listPersistentNodes[index];
        //             if (persistentNode && persistentNode["_persistentId"]) {
        //                 // const treeNodeSampPersistent = GlobalState.getNodeTreeByPersistanceId(mainModelId, persistentNode["_persistentId"]);
        //                 const treeMapPersistentId = state.tree.treeMapPersistentId[mainModelId];
        //                 const treeNodeSampPersistent = ModelTreeHelper.getNodeTreeByPersistentId(treeMapPersistentId, persistentNode["_persistentId"]);
        //                 if (treeNodeSampPersistent) {
        //                     arrNodeSelectedTrue.push(persistentNode["_nodeId"])
        //                 }
        //             }
        //         }
        //     });
        // }

        const currentTreeMap = state.tree.currentTreeMap;
        if (nodes && nodes.length > 0) {
            const fileList = state.filesList.filesOrigin;
            const { modelFileId: mainModelId } = Utils.getModelFileIdFromViewId(arrNodeSelectedTrue.length > 0 ? viewIdFinal : viewIdActive, fileList);
            if (mainModelId) {
                const finalNodes = arrNodeSelectedTrue.length > 0 ? arrNodeSelectedTrue : nodes;
                const persistentIdParams: {modelFileId: ModelFileId, persistentIds: string[]}[] = [];
                finalNodes.forEach(nodeId => {
                    const treeNode = currentTreeMap.get(nodeId.toString());
                    if (treeNode?.PersistentId) {
                        const params = persistentIdParams.find(x => x.modelFileId === (treeNode.ModelFileId || mainModelId));
                        if (params) params.persistentIds.push(treeNode.PersistentId);
                        else {
                            persistentIdParams.push({modelFileId: treeNode.ModelFileId || mainModelId, persistentIds: [treeNode.PersistentId]});
                        }
                    }
                });

                // get modelFileId of 3d file instead of sheet
                let resultModelFileId = mainModelId;
                if (viewIdActive !== viewIdFinal && Utils.getFileExtension(viewActive.name) === FileExtension.Revit) {
                    const resultFind = fileList.find(f => f.viewId === viewIdFinal)
                    if (resultFind) resultModelFileId = resultFind.modelFileId;
                }
                return merge(
                    [setLoadingProperties(true)],
                    BimApi.getExtProperty(persistentIdParams).pipe(
                        switchMap(result => {
                            let finalResult = [];
                            if (result.length === 0) {
                                finalResult = hoopsProps;
                            } else {
                                finalResult = result;
                            }
                            return [setMapPropertiesByKey({ value: finalResult, tabKey: TabTreeViewsKeys.Models, viewId: viewIdFinal, prePayload: action.payload })]
                        }),
                        catchError(err => [setLoadingProperties(false)])
                    )
                )
            }
        }
        return [clearPropertiesDataByKey({ viewId: viewIdFinal, tabKey: TabTreeViewsKeys.Models })]
    })
)

const getCostProperty$: RootEpic = (action$, state$) => action$.pipe(
    filter(getCostProperty.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const combineApi = zip(
            CMICPropertyApi.getCostCode().pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.forEach((item: CMICPropertyItem) => {
                        if (item['GjjpCode']) {
                            item._name = item['GjjpCode'];
                        }
                    });
                    const arr = Array.from(new Set(newVal.items.map(v => v._name).filter(v => !!v)));
                    return { costCode: arr }
                }),
                catchError(e => of({ costCode: [] }))
            ),
            CMICPropertyApi.getCostTypeJob().pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.forEach((item: CMICPropertyItem) => {
                        if (item['GjcatCode'] && item['GjcatName']) {
                            item._name = `${item['GjcatCode']} - ${item['GjcatName']}`;
                        }
                    });
                    const arr = Array.from(new Set(newVal.items.map(v => v._name).filter(v => !!v)));
                    return { costTypeJob: arr }
                }),
                catchError(e => of({ costType: [] }))
            ),
            CMICPropertyApi.getCostTypeCat().pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.forEach((item: CMICPropertyItem) => {
                        if (item['CatCode'] && item['CatName']) {
                            item._name = `${item['CatCode']} - ${item['CatName']}`;
                        }
                    });
                    const arr = Array.from(new Set(newVal.items.map(v => v._name).filter(v => !!v)));
                    return { costTypeCat: arr }
                }),
                catchError(e => of({ costType: [] }))
            ),
        );

        return concat(
            [propertiesSlice.actions.setCMICLoading(true)],
            combineApi.pipe(
                concatMap(val => {
                    const result = Object.assign({}, ...val) as any;
                    const sortResult: CMICProperty = {
                        costCode: result.costCode.sort(),
                        costType: [...result.costTypeJob, ...result.costTypeCat].sort()
                    };
                    return [
                        propertiesSlice.actions.setCmicProperty(sortResult),
                        propertiesSlice.actions.setCMICLoading(false),
                    ]
                }),
                catchError(e => {
                    console.warn(e);
                    return [
                        propertiesSlice.actions.setCMICLoading(false),
                    ]
                })
            )
        )
    })
)


export const PropertiesEpics = [
    updatePropByViewIdAtive$,
    setPropFormMultiNode$,
    getCostProperty$,
];
export const {
    clearPropertiesDataByKey,
    setMapPropertiesByKey,
    setLoadingProperties,
    updatePropByViewIdAtive,
    setPropFormMultiNode,
    getPropertiesFromViews,
    getCostProperty,
} = propertiesSlice.actions;
export default propertiesSlice.reducer;
