/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { MarkupPinMarkerItem } from 'container/viewer3d/markup/markup-3d/markup.pinmarker.item';
import { MarkupBaseItem } from 'container/viewer3d/markup/markup-items/markup.base.item';
import { MarkupNoteItem } from 'container/viewer3d/markup/markup-items/markup.note.item';
import { MarkupBaseOperator } from 'container/viewer3d/markup/markup-operators/markup.base.operator';
import { MarkupAction } from 'container/viewer3d/markup/markup.action';
import { UndoRedoActionInfor } from 'container/viewer3d/markup/markup.data';
import ModelState from "container/viewer3d/model/model-state";
import BaseOperator from 'container/viewer3d/operator/base.operator';
import { CuttingPlaneHelper } from "container/viewer3d/operator/cutting-plane.helper";
import { SetOperator } from "container/viewer3d/operator/operator3D.helper";
import { sortByField, sortListFiles } from 'helper/dashboard.helper';
import { MainOpenField } from 'pages/dashboard/TableFile';
import { BehaviorSubject, from, Observable, Subject } from "rxjs";
import { filter, take } from 'rxjs/operators';
import { Lodash } from 'utils/utils';
import { BookmarkCustom, DataDocumentsPdf, FileUpload, MapValueProperties, MarkupToolbarAction, PinMarkerType, ResultConfirm, StatePdfCreate, StreamFile, Thumbnail, TitleContentDialog, TreeNode, Views } from "./define";
import { ViewMode3D } from './type-markup';
import { ExtraTree, SheetData, TreeDataExtra } from "./type-state";
import { Operator3DType, OperatorType } from './type-viewer';

export class GlobalState {
    static mapPdfViewer = new Map<ViewId, StatePdfCreate>();
    static map3DViewer = new Map<ViewId, Communicator.WebViewer>();
    static mapPdfScale = new Map<ViewId, number>();
    static mapMergeChildParent = new Map<ViewId, ViewId>();
    static mapMergeParentChild = new Map<ViewId, ViewId[]>();
    static mapCombineMergeChildParent = new Map<ViewId, ViewId>(); // Quan ly bo sung phan merge combine
    static mapCombineMergeParentChild = new Map<ViewId, ViewId[]>(); // Quan ly bo sung phan merge combine
    // static mapCombineModel = new Map<ViewId, CombineChild[]>();
    static mapCombineFileChildParent = new Map<ViewId, ViewId>(); // ghi nho file root dc merge them tu cac file nao (baseViewId)
    static mapCombineFileParentChild = new Map<ViewId, ViewId[]>();
    static markupActionMap = new Map<ViewId, MarkupAction>();
    static mapViewOnlyRootChild = new Map<ViewId, ViewId[]>();
    static mapSetOperator = new Map<ViewId, SetOperator>();
    static mapCuttingPlaneHelper = new Map<ViewId, CuttingPlaneHelper>();
    static mapModel3DState = new Map<ViewId, ModelState>();
    static mapTreeData = new Map<ModelFileId, TreeDataExtra>();
    static mapTreeDataNode = new Map<ModelFileId, Map<number, TreeNode>>();
    static mapNodeSamPartInstance = new Map<ModelFileId, Map<number, number[]>>();
    static mapPropertiesData = new Map<ViewId, MapValueProperties>();
    static mapThumbnails = new Map<ViewId, Thumbnail[]>();
    static mapSheetData = new Map<ViewId, SheetData[]>();
    static subjectRedrawViewer = new Subject<number>();
    static mapViews = new Map<ViewId, Views>();
    static mapViewsSelected = new Map<ViewId, string>();
    static subjectCreateSaveView = new Subject<string>();
    static mapFileInfoRenderSuccess = new Map<ViewId, ViewId>();
    static observableRender = new Subject<ViewId>();
    static allFiles: StreamFile[] = [];
    static allFilesUpdate$: BehaviorSubject<StreamFile[]> = new BehaviorSubject<StreamFile[]>([]);
    static mapDocumentsPdf = new Map<ViewId, DataDocumentsPdf>();
    static mapBookmark = new Map<ViewId, BookmarkCustom[]>();
    static resultConfirm$ = new Subject<ResultConfirm>();
    static confirmDialog$ = new Subject<TitleContentDialog>();
    static currentActiveNote$ = new BehaviorSubject<MarkupNoteItem | null>(null);
    static markupUpdated$ = new BehaviorSubject<MarkupBaseItem | null>(null);
    static undoStack$ = new BehaviorSubject<UndoRedoActionInfor | null>(null);
    static isMarkupDragging$ = new BehaviorSubject<boolean>(false);
    static isGripDragging$ = new BehaviorSubject<boolean>(false);
    static activeOperator$ = new BehaviorSubject<MarkupToolbarAction | null>(null);
    static mapDraggingActive = new Map<ViewId, boolean>();
    static triggerUpdatePinMarker$ = new BehaviorSubject<MarkupPinMarkerItem | null>(null);
    static triggerSavePinMarker$ = new BehaviorSubject<MarkupPinMarkerItem | null>(null);
    static sheetActive$ = new BehaviorSubject<boolean>(false);
    static nodeSelectionItem$ = new BehaviorSubject<Communicator.Selection.NodeSelectionItem | null>(null);
    static containsParent = new Map<ViewId, number>();
    static rootStreamLocation = {
        location: '',
        response: {}
    };
    static markupMode: ViewMode3D = 'viewMode';
    static mapMarkupMode = new Map<ViewId, ViewMode3D>();
    static mapEnableSaveBtn = new Map<ViewId, boolean>();
    static mapEnableUndoBtn = new Map<ViewId, boolean>();
    static mapEnableRedoBtn = new Map<ViewId, boolean>();
    static mapMarkupViewSelected = new Map<ViewId, string | null>();
    static mapExpandedKeys = new Map<string, (string | number)[]>();
    static canRunCmdNavigator = true;
    static mapModePdf = new Map<ViewId, ModePdf>()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static mapJsonSetting = new Map<ViewId, any[]>();
    static mapLineWeight = new Map<ViewId, boolean>();
    static mapSwitchValueTree = new Map<ViewId, boolean>();
    static selectionEvent = true;
    static mapPersistentNodePerView = new Map<ViewId, Map<string, number[]>>()
    static list3DMarkupSelected$ = new BehaviorSubject<MarkupBaseItem[]>([]);
    static isClickOnMarkup = false;
    static mapActiveView = new Map<ViewId, Communicator.WebViewer>();
    static isInputFocus = false;
    static isInputFocus$ = new BehaviorSubject<boolean>(false);
    static isAreaSelect = false;
    static currentActiveOperator$ = new BehaviorSubject<BaseOperator | undefined>(undefined);
    static currentMarkupOperator$ = new BehaviorSubject<MarkupBaseOperator | null>(null);
    static primaryOperator = new Map<Communicator.WebViewer, Operator3DType>();
    static mapOperatorCursor = new Map<Operator3DType, string>();
    static activeNodeSelectionArr$ = new BehaviorSubject<number[]>([]);
    static pinMarkerActive$ = new BehaviorSubject<MarkupPinMarkerItem | null>(null);
    static mapActiveDraw = new Map<Communicator.WebViewer, boolean>();
    static resizeBorderDragging$ = new BehaviorSubject<boolean>(false);
    static mapPinFilterState = new Map<ViewId, PinMarkerType[]>();
    static onPinMarkerClick$ = new BehaviorSubject<(() => void) | null>(null);
    static mapCurrentDrawMode = new Map<Communicator.WebViewer, OperatorType>();
    static mapTempDrawMode = new Map<Communicator.WebViewer, OperatorType>();
    static pinMarkerCloseCuttingSection = new Map<ViewId, boolean>();
    static tooltipNodeSelection$ = new BehaviorSubject<boolean>(false);
    static synchronized = false;
    static mapInitDrawMode = new Map<Communicator.WebViewer, Communicator.DrawMode>();
    static mapViewThumnail = new Map<ViewId, string>();
    static mapIncludes2D = new Map<string, boolean>();
    static mapIncludesProps = new Map<string, boolean>();
    static mapPreviousDrawMode = new Map<ViewId, Communicator.DrawMode>();
    static orbitOperator = 0;
    
    /** All Viewer */
    static deleteViewer(list: ViewId[]): void {
        list.forEach(v => {
            const viewer = GlobalState.map3DViewer.get(v);
            if (viewer) try {
                viewer.shutdown();
            } catch (error) {
                console.warn('viewer shutdown: ', error)
            } 
            
            GlobalState.map3DViewer.delete(v);
            GlobalState.markupActionMap.delete(v);
            GlobalState.mapPdfViewer.delete(v);
            GlobalState.mapPdfScale.delete(v);
            GlobalState.mapSetOperator.delete(v);
            GlobalState.mapCuttingPlaneHelper.delete(v);
            GlobalState.mapModel3DState.delete(v);
            GlobalState.mapPropertiesData.delete(v);
            GlobalState.mapThumbnails.delete(v);
            GlobalState.mapSheetData.delete(v);
            GlobalState.mapViews.delete(v);
            GlobalState.mapViewsSelected.delete(v);
            GlobalState.mapFileInfoRenderSuccess.delete(v);
            GlobalState.mapDocumentsPdf.delete(v);
            GlobalState.mapBookmark.delete(v);
            // GlobalState.mapCombineModel.delete(v);
            GlobalState.mapModePdf.delete(v);
            GlobalState.mapSwitchValueTree.delete(v);

            GlobalState.mapMarkupMode.delete(v);
            GlobalState.mapEnableSaveBtn.delete(v);
            GlobalState.mapEnableUndoBtn.delete(v);
            GlobalState.mapEnableRedoBtn.delete(v);
            GlobalState.mapDraggingActive.delete(v);
            GlobalState.mapPinFilterState.delete(v);
            GlobalState.pinMarkerCloseCuttingSection.delete(v);
            GlobalState.mapMarkupViewSelected.delete(v);
            GlobalState.mapExpandedKeys.delete(v);
        })
    }
    /** 3DViewer */
    static getViewer3D(viewId: ViewId): Communicator.WebViewer | undefined {
        return GlobalState.map3DViewer.get(viewId);
    }
    static deleteListViewer3D(list: ViewId[]): void {
        list.forEach(v => {
            GlobalState.map3DViewer.delete(v);
            GlobalState.markupActionMap.delete(v)
        })
    }
    static getMarkupAction(viewId: ViewId): MarkupAction | undefined {
        return GlobalState.markupActionMap.get(viewId)
    }
    /** PDF viewer */
    static getPdfViewer(viewId: ViewId): PdfViewer | undefined {
        return GlobalState.mapPdfViewer.get(viewId)?.pdfViewer;
    }
    static getCurrentScale(viewId: ViewId): number | undefined {
        return GlobalState.mapPdfScale.get(viewId);
    }
    static deleteListViewerPdf(list: ViewId[]): void {
        list.forEach(v => {
            GlobalState.mapPdfViewer.delete(v);
        })
    }

    /** tuong tu nhu ham 'getViewId' nhung co xet den Combine*/
    static getViewIdCombine(viewId: ViewId): ViewId {
        return GlobalState.getParentMergeCombine(viewId) ?? viewId;
    }
    /** tuong tu nhu ham 'getParentMerge' nhung co xet den Combine*/
    static getParentMergeCombine(viewId: ViewId): ViewId | undefined {
        const baseViewId = GlobalState.mapCombineMergeChildParent.get(viewId);
        if (baseViewId) return baseViewId
        return GlobalState.mapMergeChildParent.get(viewId);
    }
    /** Merge */
    static getViewId(viewId: ViewId): ViewId {
        return GlobalState.getParentMerge(viewId) ?? viewId;
    }
    static getParentMerge(viewId: ViewId): ViewId | undefined {
        // const baseViewId = GlobalState.mapCombineMergeChildParent.get(viewId);
        // if (baseViewId) return baseViewId 
        return GlobalState.mapMergeChildParent.get(viewId);
    }
    static getParentChildrenMerge(viewId: ViewId): { parentId: ViewId, children: ViewId[] } | undefined {
        const parentId = GlobalState.mapMergeChildParent.get(viewId);
        if (parentId) {
            const listChildren = GlobalState.mapMergeParentChild.get(parentId);
            if (listChildren) {
                return {
                    parentId,
                    children: listChildren
                }
            }
        }
        return undefined;
    }

    static getParentChildrenMergeCombine(viewId: ViewId): { parentId: ViewId, children: ViewId[] } | undefined {
        let list: string[] = [];
        let baseViewId = GlobalState.mapCombineMergeChildParent.get(viewId);
        if (!baseViewId) baseViewId = GlobalState.mapMergeChildParent.get(viewId);


        if (baseViewId) {
            const listChildrenMerge = GlobalState.mapCombineMergeParentChild.get(baseViewId as string);
            if (listChildrenMerge) list = [...listChildrenMerge];
            const listChildren = GlobalState.mapMergeParentChild.get(baseViewId as string);
            if (listChildren) list = [...list, ...listChildren];

            return {
                parentId: baseViewId,
                children: list
            }
        }
        return undefined;
    }
    static filterFileMerger(viewId: ViewId, listViewId: ViewId[]): ViewId[] | undefined {
        const parentChildrenMerge = GlobalState.getParentChildrenMerge(viewId);
        if (parentChildrenMerge) {
            const result = listViewId.filter(v => parentChildrenMerge.children.includes(v));
            return result.length > 0 ? result : undefined;
        }
        return undefined;
    }
    static mapListMerge(listViewId: ViewId[]): ViewId[] {
        return listViewId.map(v => GlobalState.getParentMerge(v) ?? v)
    }
    
    static mapListMergeCombine(listViewId: ViewId[]): ViewId[] {
        return listViewId.map(v => GlobalState.getParentMergeCombine(v) ?? v)
    }
    
    static isFileChild(viewId: ViewId): ViewId | undefined {
        const parent = GlobalState.mapMergeChildParent.get(viewId);
        if (parent && parent !== viewId) {
            return viewId
        }
    }
    /** End merge */

    /** View only */
    static addNewChildViewOnly(rootViewId: ViewId, newChildId: ViewId): void {
        const currentListChildren = GlobalState.mapViewOnlyRootChild.get(rootViewId);
        const newListChildren = currentListChildren ? [...currentListChildren, newChildId] : [newChildId];
        GlobalState.mapViewOnlyRootChild.set(rootViewId, newListChildren);
    }
    static getRootViewOnly(viewChild: ViewId): ViewId | undefined {
        let result: ViewId | undefined = undefined;
        GlobalState.mapViewOnlyRootChild.forEach((children: ViewId[], rootView: ViewId) => {
            if (children) {
                const viewChildFind = children.find(c => c === viewChild);
                if (viewChildFind) {
                    result = rootView
                }
            }
        })
        return result
    }
    static getChildrenByRootId(rootId: ViewId): ViewId[] {
        return GlobalState.mapViewOnlyRootChild.get(rootId) ?? []
    }
    static isRootViewOnly(viewId: ViewId): boolean {
        return !!GlobalState.mapViewOnlyRootChild.get(viewId)
    }
    static deleteMapRootViewOnly(viewRootId: ViewId): void {
        GlobalState.mapViewOnlyRootChild.delete(viewRootId)
    }
    static addMapSetOperator(viewId: ViewId): SetOperator {
        const webviewer = GlobalState.map3DViewer.get(viewId);
        const operatorClass = new SetOperator(webviewer);
        GlobalState.mapSetOperator.set(viewId, operatorClass);
        return operatorClass
    }
    static getSetOperator(viewId: ViewId): SetOperator {
        const result = GlobalState.mapSetOperator.get(viewId);
        return result && result.viewer ? result : GlobalState.addMapSetOperator(viewId)
    }
    static deleteSetOperator(list: ViewId[]): void {
        list.forEach(v => {
            GlobalState.mapSetOperator.delete(v);
        })
    }
    static getCuttingPlaneHelper(viewId: ViewId): CuttingPlaneHelper | undefined {
        const result = GlobalState.mapCuttingPlaneHelper.get(viewId);
        if (!result) {
            const setOperator = GlobalState.getSetOperator(viewId);
            const webviewer = GlobalState.map3DViewer.get(viewId);
            if (webviewer) {
                const newClassCuttingHelper = new CuttingPlaneHelper(webviewer, setOperator);
                GlobalState.mapCuttingPlaneHelper.set(viewId, newClassCuttingHelper);
                return newClassCuttingHelper
            }
        }
        return result
    }
    static getModel3DState(viewId: ViewId): ModelState | undefined {
        const result = this.mapModel3DState.get(viewId);
        if (!result) {
            const viewer = this.map3DViewer.get(viewId);
            if (viewer) {
                const modelState = new ModelState(viewer);
                this.mapModel3DState.set(viewId, modelState);
                return modelState;
            }
        }
        return result;
    }
    static getTreeDataByModelFileId(modelFileId: ModelFileId): TreeNode[] | undefined {
        const treeDataExtra = GlobalState.mapTreeData.get(modelFileId);
        if (!treeDataExtra) {
            return undefined
        }
        return treeDataExtra.data
    }
    static getTreeExtraByModelFileId(modelFileId: ModelFileId): ExtraTree | undefined {
        const treeDataExtra = GlobalState.mapTreeData.get(modelFileId);
        if (!treeDataExtra) {
            return undefined
        }
        return treeDataExtra.extra
    }

    static getTreeNodesByTreeNodeId(modelFileId: ModelFileId, selectionNodeIds: number[]): TreeNode[] {
        const treeNodeRet: TreeNode[] = [];
        const mapNode = GlobalState.mapTreeDataNode.get(modelFileId);
        if (mapNode) {
            selectionNodeIds.forEach(nodeId => {
                const treeNode = mapNode.get(nodeId);
                if (treeNode && treeNode.persistentId)
                    treeNodeRet.push(treeNode);
            });
        }
        return treeNodeRet
    }

    static getPersistentIdsByTreeNodeId(modelFileId: ModelFileId, selectionNodeIds: number[]): string[] {
        const persistentIds: string[] = [];
        const mapNode = GlobalState.mapTreeDataNode.get(modelFileId);
        if (mapNode) {
            selectionNodeIds.forEach(nodeId => {
                const treeNode = mapNode.get(nodeId);
                if (treeNode && treeNode.persistentId && !persistentIds.includes(treeNode.persistentId))
                    persistentIds.push(treeNode.persistentId);
            });
        }
        return persistentIds
    }
    static getSinglePersistentIdFromNodeId(modelFileId: ModelFileId, nodeId: number): string | undefined {
        const mapNode = GlobalState.mapTreeDataNode.get(modelFileId);
        if (!mapNode) return undefined;
        const treeNode = mapNode.get(nodeId);
        if (treeNode && treeNode.persistentId) return treeNode.persistentId
        return undefined;
    }
    static getTreeNodeIdsByPersistentIds(modelFileId: ModelFileId, persistentIds: string[]): number[] {
        const treeNodes: number[] = [];

        const mapNode = GlobalState.mapTreeDataNode.get(modelFileId);
        if (mapNode) {
            mapNode.forEach(element => {
                if (element.persistentId && persistentIds.includes(element.persistentId))
                    treeNodes.push(element.key);
            });
        }
        return treeNodes
    }

    static getNodeTreeByPersistanceId(modelFileId: ModelFileId, persistentIdGet: string): TreeNode[] {
        const mapNode = GlobalState.mapTreeDataNode.get(modelFileId);
        const treenode: TreeNode[] = [];
        if (mapNode) {
            mapNode.forEach(element => {
                const persistentId = element.persistentId;
                if (persistentId && persistentId === persistentIdGet)
                    treenode.push(element);
            });
        }
        return treenode;
    }

    static AddNodeToMapNode(modelFileId: ModelFileId, nodes: TreeNode[]): void {
        const mapNodes = GlobalState.mapTreeDataNode.get(modelFileId);
        if (mapNodes !== undefined) {
            nodes.forEach(node => {
                mapNodes?.set(node.key, node);
                if (node.children && node.children.length > 0)
                    GlobalState.AddNodeToMapNode(modelFileId, node.children);
            });
        }
    }

    static GenerateMapTreeDataNode(modelFileId: ModelFileId): void {
        const mapNode = GlobalState.mapTreeDataNode.get(modelFileId);
        if (!mapNode) {
            const mapNodeNew = new Map<number, TreeNode>();
            GlobalState.mapTreeDataNode.set(modelFileId, mapNodeNew);
            const treeNodes = GlobalState.getTreeDataByModelFileId(modelFileId);
            if (treeNodes)
                GlobalState.AddNodeToMapNode(modelFileId, treeNodes);
        }
    }

    static AddNodeSamPartInstance(modelFileId: ModelFileId, viewerActive: Communicator.WebViewer, nodeIds: number[]): void {
        const mapNodesSamePart = GlobalState.mapNodeSamPartInstance.get(modelFileId);
        if (mapNodesSamePart !== undefined) {
            nodeIds.forEach(node => {
                if (viewerActive.model.getNodeType(node) === Communicator.NodeType.PartInstance) {
                    viewerActive.model.getNodesInstancingSamePart(node).then((nodeSamePart) => {
                        if (nodeSamePart) {
                            mapNodesSamePart.set(node, nodeSamePart);
                        }
                    })
                }
                const childs = viewerActive.model.getNodeChildren(node);
                if (childs && childs.length > 0)
                    return GlobalState.AddNodeSamPartInstance(modelFileId, viewerActive, childs);
                else
                    return;
            });
        }
    }

    static GenerateMapNodeSamPartInstance(modelFileId: ModelFileId, viewerActive: Communicator.WebViewer): void {
        const mapNode = GlobalState.mapNodeSamPartInstance.get(modelFileId);
        if (!mapNode) {
            const mapNodeNew = new Map<number, number[]>();
            GlobalState.mapNodeSamPartInstance.set(modelFileId, mapNodeNew);
            const rootNode = viewerActive.model.getAbsoluteRootNode();
            const childs = viewerActive.model.getNodeChildren(rootNode);
            if (childs)
                return GlobalState.AddNodeSamPartInstance(modelFileId, viewerActive, childs);
        }
    }

    static arrayEquals(a: number[], b: number[]): boolean {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }
    static GetPesistentsIdByNodeIds(viewId: ViewId, nodeId: number): string | undefined {
        const mapPersistentNode = this.mapPersistentNodePerView.get(viewId);
        if (mapPersistentNode) {
            for (const entry of Array.from(mapPersistentNode.entries())) {
                if (entry[1].includes(nodeId))
                    return entry[0];
            }
        }
        return undefined;
    }

    static getNodeidsByPersistanceId(viewerActive: Communicator.WebViewer, viewId: ViewId, persistent: string): number[] | undefined {
        const MapPersistentNode = this.mapPersistentNodePerView.get(viewId);
        if (MapPersistentNode) {
            return MapPersistentNode.get(persistent);
        }
        return undefined
    }
    static AddPersistentToMapPersistent(viewerActive: Communicator.WebViewer, viewId: ViewId, nodes: number[]): void {
        const mapPersistentNode = GlobalState.mapPersistentNodePerView.get(viewId);
        if (mapPersistentNode !== undefined) {
            nodes.forEach(node => {
                const type = viewerActive.model.getNodeType(node);
                if (type !== Communicator.NodeType.AssemblyNode) {
                    //add persistent into map
                    viewerActive.model.getNodeProperties(node).then((val) => {
                        const persistentId = val && val['persistentId']
                        if (persistentId) {
                            const nodeIds = mapPersistentNode.get(persistentId);
                            if (nodeIds) {
                                nodeIds.push(node)
                            } else {
                                mapPersistentNode.set(persistentId, [node]);
                            }
                        }
                    });
                }
                const childs = viewerActive.model.getNodeChildren(node);
                if (childs && childs.length > 0)
                    GlobalState.AddPersistentToMapPersistent(viewerActive, viewId, childs);
                else
                    return
            });
        }
    }

    static GenerateMapPesistentNode(viewerActive: Communicator.WebViewer, viewId: ViewId): void {
        const mapNode = GlobalState.mapPersistentNodePerView.get(viewId);
        if (!mapNode) {
            const mapPesistentNode = new Map<string, number[]>();
            GlobalState.mapPersistentNodePerView.set(viewId, mapPesistentNode);

            const rootNode = viewerActive.model.getAbsoluteRootNode();
            const childs = viewerActive.model.getNodeChildren(rootNode);
            if (childs)
                return GlobalState.AddPersistentToMapPersistent(viewerActive, viewId, childs);
        }
    }

    static CheckNodeIsExistInTree(modelFileId: ModelFileId, idCheck: number): boolean {
        const mapNode = GlobalState.mapTreeDataNode.get(modelFileId);
        if (mapNode) {
            const node = mapNode.get(idCheck);
            if (node) return true;
        }
        return false;
    }

    static IsNodeIdInNode(treeNode: TreeNode, idCheck: number): boolean {
        if (treeNode.key === idCheck)
            return true;
        let result = false;
        if (treeNode.children) {
            treeNode.children.forEach(value => {
                if (value.key === idCheck) {
                    result = true;
                    return true;
                } else {
                    if (value.children && value.children.length !== 0) {
                        if (this.IsNodeIdInNode(value, idCheck)) {
                            result = true;
                            return true;
                        }
                    }
                }
            });
        }
        return result;
    }

    static getComponentNodeIdInTree(modelFileId: ModelFileId, viewIdFinal: ViewId, selectedIds: number[]): number[] {
        const retNodeIds: number[] = [];
        const treeDataExtra = GlobalState.mapTreeData.get(modelFileId);
        const viewer = this.getViewer3D(viewIdFinal);

        if (treeDataExtra && viewer) {
            const rootNode = viewer.model.getAbsoluteRootNode();
            selectedIds.forEach(selectedId => {
                treeDataExtra.data.forEach(cateNode => {
                    if (this.IsNodeIdInNode(cateNode, selectedId)) {
                        retNodeIds.push(selectedId);
                    }
                    else {
                        let parentKey = viewer.model.getNodeParent(selectedId);
                        while (parentKey != null) {
                            if (this.IsNodeIdInNode(cateNode, parentKey)) {
                                retNodeIds.push(parentKey);
                                break;
                            }
                            else {
                                parentKey = viewer.model.getNodeParent(parentKey);
                            }
                        }
                    }
                });
            })
        }
        return retNodeIds;
    }

    static redrawViewer(number = 60): void {
        GlobalState.subjectRedrawViewer.next(number);
    }
    static getThumbnailPage(viewId: ViewId, pageIndex: number): string | undefined {
        const arrThumbnails = GlobalState.mapThumbnails.get(viewId);
        if (arrThumbnails) {
            const thumbnail = arrThumbnails.find(t => t.pageIndex === pageIndex);
            return thumbnail?.thumbnail
        }
        return
    }
    static setThumbnailPage(viewId: ViewId, pageIndex: number, thumbnail: string): void {
        const arrThumbnails = GlobalState.mapThumbnails.get(viewId);
        if (arrThumbnails) {
            const preThumbnail = arrThumbnails.find(t => t.pageIndex === pageIndex);
            if (preThumbnail) {
                preThumbnail.thumbnail = thumbnail
            } else {
                const newThumbnail: Thumbnail = {
                    pageIndex,
                    thumbnail
                };
                arrThumbnails.push(newThumbnail)
            }
        } else {
            const newThumbnails: Thumbnail[] = [
                { pageIndex, thumbnail }
            ];
            GlobalState.mapThumbnails.set(viewId, newThumbnails)
        }
    }
    /** end thumbnail pdf */
    /** render success file */
    static hookStreamRender(viewId: ViewId): Observable<ViewId> {
        if (GlobalState.mapFileInfoRenderSuccess.has(viewId)) {
            return from([viewId])
        }
        return GlobalState.observableRender.pipe(
            filter(v => v === viewId),
            take(1)
        )
    }
    /** end render success file */

    /** Page open file */
    static setAllFile(allFiles: StreamFile[]): void {
        GlobalState.allFiles = allFiles;
    }
    static getAllFile(): StreamFile[] {
        return GlobalState.allFiles;
    }

    static getNestedFolderFile(subFolders: string[]): StreamFile[] {
        const folderFiles: StreamFile[] = [];
        let lstFilesInFolder = [...GlobalState.allFiles];
        const len = subFolders.length;
        // with arraypath from root
        for (let i = 1; i < len; i++) {
            const sIndex = lstFilesInFolder.findIndex(item => item.filename === subFolders[i] && item.isDirectory === true);
            if (sIndex !== -1) {
                const folder = { ...lstFilesInFolder[sIndex] };
                folderFiles.push(folder);
                lstFilesInFolder = folder.child;
            }
        }
        return folderFiles;
    }

    static getAllFilesInFolder(folder: string): StreamFile[] {
        const indx = GlobalState.allFiles.findIndex(item => {
            const curDir = item.isDirectory ? `${item.originalFilePath}/${item.filename}` : item.originalFilePath;
            return curDir === folder;
        });
        if (indx !== -1) {
            return GlobalState.allFiles[indx].child;
        }
        return [];
    }

    static sortField(list: StreamFile[], type: MainOpenField, isAscending: boolean): StreamFile[] {
        return sortByField(list, type, isAscending);
    }

    static sortFiles(currentPath: string, searchText: string): StreamFile[] {
        let all: StreamFile[] = [];
        const list = GlobalState.allFiles.filter(val => val.originalFilePath === currentPath);
        if (searchText === '') {
            all = sortListFiles(list);
        }
        else {
            const temp = GlobalState.getAllFile().filter(val => {
                const originalFilePath = val.originalFilePath + '/';
                if (!originalFilePath.startsWith(currentPath + '/')) return false;
                return val.filename.toLowerCase().includes(searchText.toLowerCase())
            });
            all = sortListFiles(temp);
        }
        return all;
    }
    static addFile(file: StreamFile, currentPath: string, searchText: string): FileUpload {
        const allFiles = GlobalState.getAllFile();
        allFiles.push(file);
        GlobalState.setAllFile(sortListFiles(allFiles));
        const list = GlobalState.sortFiles(currentPath, searchText);
        const ret: FileUpload = {
            index: list.findIndex(val => val.baseFileId === file.baseFileId),
            all: list
        }
        return ret;
    }

    static updateListFiles(all: StreamFile[]): void {
        GlobalState.allFilesUpdate$.next([...all]);
    }
    /** End Page open file */

    /** Bootstrap */
    static dispatchConfirmDialog$(prop: TitleContentDialog): Observable<ResultConfirm> {
        GlobalState.confirmDialog$.next(prop);
        return GlobalState.resultConfirm$.pipe(take(1));
    }
    /** End boostrap */
    static toggleHighlightAllPinMarker(viewer: Communicator.WebViewer, isHighlight: boolean): void {
        const allMarkups: MarkupBaseItem[] = viewer.markupManager._getItemManager()._markupItems.toJSON().map((v: any[]) => v[1]);
        allMarkups.forEach(v => {
            if (v.isMarkup3D) (v as MarkupPinMarkerItem).toggleActiveBorder(isHighlight);
        })
    }

    static addMergeFileCombine(listBaseViewId: string[], baseMergeViewId: string): void {
        listBaseViewId.forEach(baseViewId => {
            // set infor mapCombineFileChildParent, infor cho baseViewId 
            GlobalState.mapCombineFileChildParent.set(baseViewId, baseMergeViewId);
            const curList = GlobalState.mapCombineFileParentChild.get(baseMergeViewId);
            if (!curList || curList.length < 1) GlobalState.mapCombineFileParentChild.set(baseMergeViewId, [baseViewId]);
            else {
                if (!curList.includes(baseViewId)) {
                    curList.push(baseViewId);
                    GlobalState.mapCombineFileParentChild.set(baseMergeViewId, curList);
                }
            }

            // set infor mapMergeParentChild, infor cho viewId
            let listChild = GlobalState.mapMergeParentChild.get(baseViewId as string);
            if (listChild) { // mapCombineMergeParentChild: bo sung cac file merge cua file bi combine cua file combine (merge)
                const list = GlobalState.mapCombineMergeParentChild.get(baseMergeViewId as string);
                if (list) listChild = [...listChild, ...list];
                GlobalState.mapCombineMergeParentChild.set(baseMergeViewId as string, listChild);
                listChild.forEach(v => {
                    GlobalState.mapCombineMergeChildParent.set(v, baseMergeViewId as string);
                })
            } else GlobalState.mapCombineMergeChildParent.set(baseViewId, baseMergeViewId as string);
        })
        
    }
    static removeMergeFileCombine(listBaseViewId: string[], baseMergeViewId: string): void {
        listBaseViewId.forEach(baseViewId => {
            // remove infor mapCombineFileChildParent, infor cho baseViewId 
            GlobalState.mapCombineFileChildParent.delete(baseViewId)
            const curList = GlobalState.mapCombineFileParentChild.get(baseMergeViewId);
            if (curList && curList.length > 0) {
                if (curList.includes(baseViewId)) {
                    Lodash.remove(curList, v => v === baseViewId);
                    GlobalState.mapCombineFileParentChild.set(baseMergeViewId, curList);
                }
            }

            // remove infor mapMergeParentChild, infor cho viewId
            let listChild = GlobalState.mapMergeParentChild.get(baseViewId as string);
            if (listChild) { // mapCombineMergeParentChild: huy bo cac file merge cua file bi combine cua file combine (merge)
                listChild.forEach(v => {
                    GlobalState.mapCombineMergeChildParent.delete(v);
                })
                
                const list = GlobalState.mapCombineMergeParentChild.get(baseMergeViewId as string);
                
                if (list) {
                    listChild = listChild?.filter(v => !list.includes(v))
                    GlobalState.mapCombineMergeParentChild.set(baseMergeViewId as string, listChild);
                }
                
            } else GlobalState.mapCombineMergeChildParent.delete(baseViewId);
        });
                    
        
    }

    /**
     * 
     * @param viewId 
     * @returns 0: none, 1: parent, 2: child
     */
    static getCombineFileStatus(viewId: string): number {
        const baseViewId = GlobalState.getViewId(viewId);
        const curList = GlobalState.mapCombineFileParentChild.get(baseViewId);
        if (curList && curList.length > 0) return 1; // parent
        
        const id = GlobalState.mapCombineFileChildParent.get(baseViewId);
        if (id) return 2; // child

        return 0;
    }

    static setSynchronized(sync: boolean): void {
        GlobalState.synchronized = sync;
    }
}
