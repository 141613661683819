module.exports = {
    palette: {
        primary: '#0D1C36',
        secondary: '#56a97a',
        'gray-bg': '#FBFBFD',
        border: '#F0F0F0',
        gray: '#AAB2BF',
        disabled: 'rgba(0, 0, 0, 0.26)',
    },
    zIndex: {
        default: 1000,
        loading: 1300,
        panel: 50
    }
}
