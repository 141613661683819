import { SettingState } from "common/define";
import { GlobalState } from "common/global";
import { OperatorType } from "common/type-viewer";
import Utils from "utils/utils";
import ModelHelper from "../model/model.helper";
import AreaSelectOperator from "./areaSelect.operator";
import BaseOperator from "./base.operator";
import OrbitOperator from "./orbit.operator";
import PanOperator from "./pan.operator";
import SelectOperator from "./select.operator";
import TurntableOperator from "./turntable.operator";
import WalkOperator from "./walk.operator";
import ZoomWindowOperator from "./zoom-window.operator";

export const mapOperatorTypeDrawMode: Partial<Record<OperatorType, Communicator.DrawMode>> = {
    "drawMode-wireframe": Communicator.DrawMode.Wireframe,
    "drawMode-shaded": Communicator.DrawMode.Shaded,
    "drawMode-shaded-wire": Communicator.DrawMode.WireframeOnShaded,
    "drawMode-hidden-line": Communicator.DrawMode.HiddenLine,
    "drawMode-xray": Communicator.DrawMode.XRay,
    "drawMode-ghosting": Communicator.DrawMode.Ghost,
};
export const mapDrawModeOperator: Partial<Record<Communicator.DrawMode, OperatorType>> = {
    0: "drawMode-wireframe",
    1: "drawMode-shaded",
    2: "drawMode-shaded-wire",
    3: "drawMode-hidden-line",
    4: "drawMode-xray",
    10001: "drawMode-ghosting"
}

export class SetOperator {
    viewer: Communicator.WebViewer | undefined;
    get currentOperator(): BaseOperator | undefined {
        return this._currentOperator;
    }
    set currentOperator(newOperator: BaseOperator | undefined) {
        GlobalState.currentActiveOperator$.next(newOperator);
        this.setCurrentOperator(newOperator);
    }

    constructor(viewer: Communicator.WebViewer | undefined) {
        this.viewer = viewer;
    }

    callFunctionHome(): boolean {
        if (this.viewer) {
            this.resetHome()
            return true;
        }
        return false;
    }
    resetModel(): boolean {
        if (this.viewer) {
            const drawMode = GlobalState.mapInitDrawMode.get(this.viewer);
            if (drawMode) {
                const opeDrawMode = mapDrawModeOperator[drawMode]
                !this.viewer.sheetManager.isDrawingSheetActive() && opeDrawMode && this.setOperatorDrawMode(opeDrawMode);
                // this.viewer.selectPart(null);
                return true
            }
        }
        return false
    }
    showAll(type: OperatorType): boolean {
        if (this.viewer) {
            !this.viewer.sheetManager.isDrawingSheetActive() && this.setOperatorDrawMode(type);
            return true
        }
        return false
    }
    setPanOperator(): boolean {
        if (this.viewer) {
            this.currentOperator = new PanOperator(this.viewer);
            return true;
        }
        return false;
    }

    setOrbitOperator(viewId: string): boolean {
        if (this.viewer) {
            this.currentOperator = new OrbitOperator(this.viewer, viewId);
            return true;
        }
        return false;
    }

    setOperatorSelect(viewId: ViewId): boolean {
        if (this.viewer) {
            this.currentOperator = new SelectOperator(this.viewer, viewId);
            return true;
        }
        return false;
    }

    setOperatorAreaSelect(): boolean {
        if (this.viewer) {
            this.currentOperator = new AreaSelectOperator(this.viewer);
            return true;
        }
        return false;
    }

    setOperatorZoomIn(): boolean {
        this.viewer && zoomOperator("in", this.viewer);
        return false;
    }
    setOperatorZoomOut(): boolean {
        this.viewer && zoomOperator("out", this.viewer);
        return false;
    }
    setOperatorZoomWindow(): boolean {
        if (this.viewer) {
            this.currentOperator = new ZoomWindowOperator(this.viewer);
            return true;
        }
        return false;
    }
    setOperatorZoomFit(): boolean {
        if (this.viewer) {
            ModelHelper.zoomFit(this.viewer)
        }
        return false;
    }
    setOperatorRotateZ(): boolean {
        if (this.viewer) {
            this.currentOperator = new TurntableOperator(
                this.viewer,
                Communicator.Axis.Z
            );
            return true;
        }
        return false;
    }
    setOperatorWalkThrough(): boolean {
        if (this.viewer) {
            this.currentOperator = new WalkOperator(this.viewer);
            this.viewer.getViewElement().focus();
            return true;
        }
        return false;
    }
    setOperatorDrawMode(type: OperatorType, isSelectInTree = false): boolean {
        if (this.viewer) {
            setDrawMode(this.viewer, type, isSelectInTree);
            return true;
        }
        return false;
    }
    escape(viewId: string): void {
        if (this._currentOperator) {
            this._currentOperator.Escape();
            this._currentOperator.InActive();
        }
        this.setOperatorSelect(viewId);
    }
    checkDefault(): boolean {
        return this._currentOperator instanceof SelectOperator
    }

    /** private */
    private _currentOperator: BaseOperator | undefined;
    private setCurrentOperator(newOperator: BaseOperator | undefined) {
        if (newOperator !== this._currentOperator && newOperator) {
            if (this._currentOperator) {
                this._currentOperator.Escape();
                this._currentOperator.InActive();
            }
            newOperator.Active();
            this._currentOperator = newOperator;
        }
    }
    private async resetHome() {
        if (this.viewer?.sheetManager.isDrawingSheetActive()) {
            this.viewer.view.setProjectionMode(Communicator.Projection.Orthographic);
            this.viewer.fitWorld();
        }
        else
            await this.viewer?.view.resetCamera(Communicator.DefaultTransitionDuration);
    }
}

/** functions */
function zoomOperator(
    type: "in" | "out",
    viewer: Communicator.WebViewer
): void {
    const delta = type === "out" ? -1 : 1;
    const cs = viewer.view.getCanvasSize();
    const even = new Communicator.Event.MouseWheelInputEvent(
        cs.x / 2,
        cs.y / 2,
        delta,
        Communicator.Buttons.None,
        Communicator.KeyModifiers.None,
        Communicator.MouseInputType.Down
    );
    viewer.operatorManager.getOperator(Communicator.OperatorId.Zoom).onMousewheel(even);
}


function updateXRayGhostOpacity(viewer: Communicator.WebViewer) {
    const currXRayOpacity = viewer.view.xrayOpacity;
    const currGhostOpacity = viewer.view.ghostOpacity;
    const setting: SettingState | null = Utils.getValueLocalStorage('setting');
    const xRayOpacity = setting ? setting.general.xRayOpacity : currXRayOpacity;
    const ghostOpacity = setting ? setting.general.ghostingOpacity : currGhostOpacity;
    ModelHelper.applyXrayOpacity(viewer, xRayOpacity, ghostOpacity)
}

function setDrawMode(viewer: Communicator.WebViewer, type: OperatorType, isSelectInTree = false) {
    const mapOperatorTypeDrawMode: Partial<Record<OperatorType, Communicator.DrawMode>> = {
        "drawMode-wireframe": Communicator.DrawMode.Wireframe,
        "drawMode-shaded": Communicator.DrawMode.Shaded,
        "drawMode-shaded-wire": Communicator.DrawMode.WireframeOnShaded,
        "drawMode-hidden-line": Communicator.DrawMode.HiddenLine,
        "drawMode-xray": Communicator.DrawMode.XRay,
        "drawMode-ghosting": Communicator.DrawMode.Ghost,
    };
    const mode = mapOperatorTypeDrawMode[type];
    const currentMode = viewer.view.getDrawMode();
    if (currentMode === mode) return;
    if (mode !== undefined) {
        if (mode !== Communicator.DrawMode.XRay && mode !== Communicator.DrawMode.Ghost) {
            viewer.view.setDrawMode(mode);
            if (mode === Communicator.DrawMode.HiddenLine) {
                const { top, bottom } = viewer.view.getBackgroundColor();
                ModelHelper.configHiddenLineMode(viewer, top, bottom)
            }
        } else {
            const rootNodeId = ModelHelper.getRootNodeId(viewer);
            if (rootNodeId !== null) {
                if (mode === Communicator.DrawMode.Ghost) {
                    const selectionItems = ModelHelper.getSelectionItemId(viewer);
                    selectionItems.forEach(node => viewer.selectPart(node, Communicator.SelectionMode.Set));
                    ModelHelper.unGhostingNodes(viewer);
                }
                viewer.view.setDrawMode(mode);
            }
        }
        GlobalState.mapCurrentDrawMode.set(viewer, type);
        // if (!isSelectInTree) GlobalState.mapTempDrawMode.set(viewer, type);
    }
    updateXRayGhostOpacity(viewer);
}