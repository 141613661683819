import { AnnotInfoExtra, AnnotJson } from "common/type-markup";
import { ModeRightPanel } from "common/type-state";
/* eslint-disable indent */

export default class UtilsPdf {
    static extraAnnotInfo(annot: AnnotJson, modeRightPanel: ModeRightPanel): AnnotInfoExtra {
        switch (annot.type) {
            case 'freetext':
                switch (annot.subject) {
                    case 'Textbox':
                        return {
                            markupMode: modeRightPanel,
                            iconName: 'markupText',
                            shapeName: 'Text Box',

                        };
                    case 'FreeTextCallout':
                        return {
                            markupMode: modeRightPanel,
                            iconName: 'callOut',
                            shapeName: 'Call out',
                        };
                }
                break;
            case 'text':
                return {
                    markupMode: modeRightPanel,
                    iconName: 'markupNote',
                    shapeName: 'Note',
                };
            case 'square':
                return {
                    markupMode: modeRightPanel,
                    iconName: 'markupRectangle',
                    shapeName: 'Rectangle',
                };
            case 'circle':
                return {
                    markupMode: modeRightPanel,
                    iconName: 'markupCircle',
                    shapeName: 'Ellipse',
                };
            case 'ink':
                switch (annot.subject) {
                    case 'arc':
                        return {
                            markupMode: modeRightPanel,
                            iconName: 'markupArc',
                            shapeName: 'Arc',
                        };
                    default:
                        return {
                            markupMode: modeRightPanel,
                            iconName: 'freehand',
                            shapeName: 'Freehand',
                        };
                }
            case 'highlight':
                return {
                    markupMode: modeRightPanel,
                    iconName: 'markupHighlight',
                    shapeName: 'Rectangle Highlight',
                };
            case 'stamp':
                return {
                    markupMode: modeRightPanel,
                    iconName: 'markupStamp',
                    shapeName: 'Stamp'
                }
            case 'line':
                switch (annot.intent) {
                    case 'LineArrow':
                        return {
                            markupMode: modeRightPanel,
                            iconName: 'markupArrow',
                            shapeName: 'Arrow',
                        };
                    default:
                        return {
                            markupMode: modeRightPanel,
                            iconName: 'markupLine',
                            shapeName: 'Line',
                        };
                }
            case 'polyline':
                return {
                    markupMode: modeRightPanel,
                    iconName: 'markupPolyline',
                    shapeName: 'Polyline',
                };
            case 'polygon':
                switch (annot.style) {
                    case "cloudy":
                        return {
                            markupMode: modeRightPanel,
                            iconName: 'polygoncloud',
                            shapeName: 'Cloud',
                        }
                    case "solid":
                        return {
                            markupMode: modeRightPanel,
                            iconName: 'markupPolygon',
                            shapeName: 'Polygon',
                        };
                }
        }
        return {
            markupMode: modeRightPanel,
            iconName: 'File',
            shapeName: 'Foxit Markup',
        };
    }

}